import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
//Auth0 config
import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";
// import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

  ReactDOM.render(
    <Auth0Provider
      domain={process.env.REACT_APP_DOMAIN}
      client_id={process.env.REACT_APP_CLIENT_ID}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>,
    document.getElementById("root")
  );

// const success = () => {
//   ReactDOM.render(
//     <Auth0Provider
//       domain={process.env.REACT_APP_DOMAIN}
//       client_id={process.env.REACT_APP_CLIENT_ID}
//       redirect_uri={window.location.origin}
//       onRedirectCallback={onRedirectCallback}
//     >
//       <App />
//     </Auth0Provider>,
//     document.getElementById("root")
//   );
// };

// const error = () => {
//   ReactDOM.render(
//     <Auth0Provider
//       domain={process.env.REACT_APP_DOMAIN}
//       client_id={process.env.REACT_APP_CLIENT_ID}
//       redirect_uri={window.location.origin}
//       onRedirectCallback={onRedirectCallback}
//     >
//       <LoadingScreen type="locationFalse" />;
//     </Auth0Provider>,
//     document.getElementById("root")
//   );
// };
// navigator.geolocation.getCurrentPosition(success, error, {
//   enableHighAccuracy: true,
//   timeout: 20000,
//   maximumAge: 0,
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

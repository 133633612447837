import React, { Component } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMapMarker as map } from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";
import { ToastsContainer, ToastsStore } from "react-toasts";
import axios from "axios";
import history from "../utils/history";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Input,
} from 'reactstrap';
// import { Alert } from "reactstrap";

class UserAdmin extends Component {

  setModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      modal: false,
      change_rol: {
        user_index: 0,
        new_rol: "",
      },
      inputs: JSON.parse(
        window.localStorage.getItem("getUsers")
        ),
      };
    this.modalUsername = React.createRef()
    this.modalPassword = React.createRef()
    this.setRol = this.setRol.bind(this);
  }

  async onChange(user_index, rol_index, newValue) {
    this.setState({
      change_rol: {
        user_index: user_index,
        new_rol: newValue,
      },
    });
    this.setModal();
  }

  // setRol= () => {
  async setRol() {
    const {
      user_index,
      new_rol
    } = this.state.change_rol;
    const username = this.modalUsername.current.value;
    const password = this.modalPassword.current.value;

    try {
      this.setModal();
      let option = "rol";
      let rol = new_rol;
      let email = this.state.inputs[user_index].email;
      const res = await axios.post(
        process.env.REACT_APP_URL_UPDATE_USER,
        {
          email,
          option,
          rol,
          username,
          password,
        }
      )
      if (res.data.updated) {
        this.state.inputs[user_index].user_metadata.rol = new_rol;
        this.setState({
          inputs: this.state.inputs,
        });
        ToastsStore.success(
          "El usuario " + email + " se actualizó con éxito"
        );
        axios.get(process.env.REACT_APP_URL_GET_ALL_USERS).then((res) => {
          window.localStorage.setItem(
            "getUsers",
            JSON.stringify(res.data)
          );
          history.push("/user-admin");
        });
      }
    } catch (error) {
      ToastsStore.error(error);
    }
  }

  deleteUser = (email, user) => {
    user.user_metadata["active"] = "false";
    let option = "user";
    if (email === JSON.parse(localStorage.user).email) {
      ToastsStore.error("No puedes eliminar tu perfil");
    } else {
      try {
        axios
          .post(process.env.REACT_APP_URL_GET_DELETE, { email, option, user })
          .then((res) => {
            if (res.data.deleted) {
              document.getElementById(email).style.display = "none";
              ToastsStore.success(
                "El usuario " + email + " se elimino con éxito"
              );
              axios.get(process.env.REACT_APP_URL_GET_ALL_USERS).then((res) => {
                window.localStorage.setItem(
                  "getUsers",
                  JSON.stringify(res.data)
                );
                history.push("/user-admin");
              });
            }
          });
      } catch (error) {
        ToastsStore.error(error);
      }
    }
  };

  next = () => {
    window.localStorage.removeItem("setName");
    window.localStorage.removeItem("setFirstName");
    window.localStorage.removeItem("setLastName");
    window.localStorage.removeItem("setEmail");
    window.localStorage.removeItem("setPassword");
    window.localStorage.removeItem("setPasswordConfirm");
    window.localStorage.removeItem("setRol");
    window.localStorage.removeItem("setGerente");
    window.localStorage.removeItem("setAdmin");

    window.localStorage.removeItem("mapUser");
    window.localStorage.removeItem("mapUserFull");
    window.localStorage.removeItem("markers");

    this.props.thisHistory.push("/create-user");
  };

  getMapFull = () => {
    this.props.thisHistory.push("/map-user");
  };

  componentDidMount() {
    if (this.state.inputs.length > 0) {
      this.setState({
        visible: false,
      });
    }
  }

  render() {
    return (
      <div className="container-fluid px-0">
        <div className="container-fluid d-flex flex-column flex-md-row px-0 mt-navbar">
          <div className="container">
            <ToastsContainer
              store={ToastsStore}
              position={"top_center"}
              lightBackground
            />
            {this.state.visible === true ? (
              <LoadingScreen type={"loading-users"} />
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <p className="title mt-30">Administrar</p>
                  </div>
                </div>
                <div className="row mt-30">
                  <div className="col-12 col-lg-3">
                    <button
                      className="btn -secondary w-100"
                      onClick={() => this.next()}
                    >
                      Agregar usuario
                    </button>
                  </div>
                  {/* <div className="col-12 col-lg-3">
                    <button
                      className="btn -secondary w-100"
                      onClick={() => this.getMapFull()}
                    >
                      Mapa
                    </button>
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="mt-30" />

                    <table className={`w-100 ${this.props.customClass}`}>
                      <thead>
                        <tr>
                          <th>Foto</th>
                          <th>Nombre</th>
                          <th>Email</th>
                          <th>Rol</th>
                          <th>Creado por</th>
                          {/* <th>Activo</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.inputs.map((input, index) =>
                          JSON.parse(window.localStorage.user).rol ===
                            "SuperAdmin" &&
                          input.user_metadata.active === "true" &&
                          input.user_metadata.environment ===
                            process.env.REACT_APP_URL_ENVIRONMENT ? (
                            <tr key={index} id={input.email}>
                              <td>
                                <img
                                  src={input.picture}
                                  alt="Profile"
                                  className="nav-user-profile rounded-circle"
                                  width="70"
                                />
                              </td>
                              <td>{input.name}</td>
                              <td>{input.email}</td>
                                <td>
                                <UncontrolledButtonDropdown>
                        <DropdownToggle
                          size="sm"
                          color="#f1f1f1"
                          caret
                          >
                            {
                              input.user_metadata.rol
                            }
                        </DropdownToggle>
                            <DropdownMenu style={{
                              maxHeight: "300px",
                              overflowY: "scroll"
                            }}>
                            {
                              JSON.parse(window.localStorage.user).email !== input.email?
                              JSON.parse(window.localStorage.getItem("getRoles")).map(
                                (rol_input, rol_index) => (

                            <DropdownItem
                              key={rol_index}
                              id={rol_index}
                              name={rol_input['name']}
                              onClick={(e) => {
                                this.onChange(index, rol_index, e.target.name);
                              }}
                            >
                              {rol_input['name']}
                            </DropdownItem>
                                )) : (
                                  <DropdownItem
                                  key={0}
                                  id={0}
                                  name={JSON.parse(window.localStorage.user).rol}
                                >
                                  {JSON.parse(window.localStorage.user).rol}
                                </DropdownItem>
                          )
                            }
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                                </td>
                              <td>{input.user_metadata.creator}</td>
                              {/* {input.user_metadata.active === "true" ? <td>Si</td> : <td>No</td>} */}
                              {/* <td>{input.last_login}</td> */}
                              <td>
                                {/* <button className="btn -fabtn -regular">
                                  <i className="fas fa-pen"></i>
                                </button> */}
                                <button
                                  className="btn -fabtn -red"
                                  onClick={(e) =>
                                    this.deleteUser(e.target.id, input)
                                  }
                                >
                                  <i
                                    id={input.email}
                                    className="fas fa-trash-alt"
                                  ></i>
                                </button>
                                {/* <button
                                  className="btn "
                                  onClick={() => this.getMap(input)}
                                >
                                  <FontAwesomeIcon icon={map} color={"green"} />
                                </button> */}
                              </td>
                            </tr>
                          ) : (input.user_metadata.creator ===
                              JSON.parse(window.localStorage.user).email &&
                              input.user_metadata.active === "true" &&
                              input.user_metadata.environment ===
                                process.env.REACT_APP_URL_ENVIRONMENT) ||
                            (input.user_metadata.levelB ===
                              JSON.parse(window.localStorage.user).email &&
                              input.user_metadata.active === "true" &&
                              input.user_metadata.environment ===
                                process.env.REACT_APP_URL_ENVIRONMENT) ? (
                            <tr key={index}>
                              <td>
                                <img
                                  src={input.picture}
                                  alt="Profile"
                                  className="nav-user-profile rounded-circle"
                                  width="70"
                                />
                              </td>
                              <td>{input.name}</td>
                              <td>{input.email}</td>
                              <td>{input.user_metadata.rol}</td>
                              <td>{input.user_metadata.creator}</td>
                              <td>
                                {/* <button className="btn -fabtn -regular">
                                  <i className="fas fa-pen"></i>
                                </button> */}
                                <button
                                  className="btn -fabtn -red"
                                  onClick={(e) =>
                                    this.deleteUser(e.target.id, input.email)
                                  }
                                >
                                  <i
                                    id={input.email}
                                    className="fas fa-trash-alt"
                                  ></i>
                                </button>
                              </td>
                            </tr>
                          ) : (
                            console.log()
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.setModal} className={this.props.className}>
        <ModalHeader className="title" toggle={this.setModal}>Cambiar Rol</ModalHeader>
        <ModalBody className="pop-text">
            ¿Deseas cambiar el rol del usuario {this.state.inputs[this.state.change_rol.user_index].email} a {this.state.change_rol.new_rol}?
            <hr></hr>
            <div>Por seguridad, necesitas confirmar tu usuario.</div>
            <div>
              <Input type="text" innerRef={this.modalUsername} placeholder="Usuario" value={JSON.parse(localStorage.user).email}/>
              <Input type="password" innerRef={this.modalPassword} placeholder="Password" />
            </div>
        </ModalBody>
        <ModalFooter className="justify-content-around">
            <button className="btn -secondaryminor" onClick={this.setModal}>No</button>
            <button className="btn -secondary" onClick={this.setRol}>Si</button>
        </ModalFooter>
      </Modal>
      </div>
    );
  }
}

export default UserAdmin;

import React, { Component } from "react";
// import { Tbody, Tr, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import Tag from "../Tag/Tag";
import axios from "axios";
import Pagination from "../../Components/Pagination/Pagination";
import FileDataContext from "../../Contexts/FileData";

class TableDetail extends Component {
  static contextType = FileDataContext
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      visible: true,
    };
  }

  componentDidMount() {
    this.getView();
  }

  componentDidUpdate(){    
    window.onpopstate = e => {
      this.context.setViewData([]);
    }
  }

  getView() {
    let uuid = window.localStorage.getItem("uuidActive");
    let client = window.localStorage.getItem("clientActive");
    let email = "";

    if (window.localStorage.getItem("ejecutivoActive") === null) {
      email = JSON.parse(window.localStorage.getItem("user")).email;
    } else {
      email = window.localStorage.getItem("ejecutivoActive");
    }
    if (this.context.viewData && this.context.viewData.length) {
      this.setState({
        visible: false,
      });
    }
    const hostname = process.env.REACT_APP_VIEW;
    axios
      .post(hostname, {
        email,
        client,
        uuid,
      })
      .then((res) => {
        if (!(this.context.viewData && this.context.viewData.length)) {
          this.context.setViewData(res.data);
        }
        this.setState({
          visible: false,
        });
      });
  }

  render() {
    return this.state.visible === true ? (
      <div></div>
    ) : (
      // <Tbody>
      //   {JSON.parse(window.localStorage.getItem("viewData")).map(
      //     (input, index) => (
      //       <Tr key={input.RFC} className="table-row mb-0 mt-0">
      //         <Td>{input.RFC}</Td>
      //         <Td>{input.IngresoNM}</Td>
      //         <Td>
      //           <Tag
      //             text={input.Tarifaconveniente}
      //             color={"-" + input.Tarifaconveniente}
      //           />
      //         </Td>
      //         <Td>{input.Diferencia}</Td>
      //       </Tr>
      //     )
      //   )}
      // </Tbody>

      <div className="row mt-20">
        <div className="col-12 overflow-x-scroll">
          <Pagination />
        </div>
      </div>
    );
  }
}

export default TableDetail;

//Init resources
import React from "react";
import { Router, Switch } from "react-router-dom";
//private resources
import PrivateRoute from "./Components/PrivateRoute";
import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";
//routers
import NavBar from "./Components/Navbar/Navbar";
import Sidenav from "./Components/Sidenav/Sidenav";
//aAUth0
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
//import Rol from "./utils/rol";
// styles
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
//Personal resources
import Agenda from "./Pages/Agenda";
import Upload from "./Pages/Upload";
import ClientStory from "./Pages/Client-story";
import ClientDetail from "./Pages/Client-detail";
import EditUser from "./Pages/Edit-user";
import Landing from "./Pages/Landing";
import UserAdmin from "./Pages/User-admin";
import NetAmount from "./Pages/Net-amount";
import CreateUser from "./Pages/Create-user";
import MapUser from "./Pages/Map-user";
import Profile from "./Pages/Profile";
import { FileDataProvider } from "./Contexts/FileData";

const App = () => {
  const { loading, user, isAuthenticated, logout } = useAuth0();
  window.localStorage.setItem("aa", true);
  window.localStorage.setItem("errorArchivo", false);
  //window.localStorage.setItem("history", history);
  if (loading) {
    return <LoadingScreen type="loading-main" />;
  }
  if (isAuthenticated) {
  } else {
    window.localStorage.removeItem("Rol");
  }
  return (
    <Router history={history}>
      <div id="app" className="d-flex">
        <NavBar />
        <Sidenav thisUser={user} />
        <Switch>
          <PrivateRoute
            path="/"
            exact
            component={(props) => (
              <Agenda
                {...props}
                logout={logout}
                thisHistory={history}
                thisUser={user}
              />
            )}
          />
          <FileDataProvider>
          <PrivateRoute
            path="/upload"
            component={(props) => (
              <Upload {...props} thisHistory={history} thisUser={user} />
            )}
          />

          <PrivateRoute
            path="/client-story"
            component={(props) => (
              <ClientStory {...props} thisHistory={history} thisUser={user} />
            )}
          />
          <PrivateRoute path="/client-detail" component={ClientDetail} />
          <PrivateRoute path="/edit-user" component={EditUser} />
          <PrivateRoute path="/landing" component={Landing} />
          <PrivateRoute path="/create-user" component={CreateUser} />
          <PrivateRoute
            path="/user-admin"
            component={(props) => (
              <UserAdmin {...props} thisHistory={history} />
            )}
          />
          <PrivateRoute
            path="/net-amount"
            component={(props) => (
              <NetAmount {...props} thisHistory={history} thisUser={user} />
            )}
          />
          </FileDataProvider>
          <PrivateRoute
            path="/profile"
            component={(props) => (
              <Profile {...props} thisHistory={history} thisUser={user} />
            )}
          />

          <PrivateRoute
            path="/map-user"
            component={(props) => (
              <MapUser {...props} thisHistory={history} thisUser={user} />
            )}
          />
        </Switch>
      </div>
    </Router>
  );
};

export default App;

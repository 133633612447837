import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";
import { NavLink } from "../../node_modules/reactstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Button, Label } from "reactstrap";
import FileSaver from "file-saver";
import DragDrop from "../Components/Drag-n-drop/Drag-n-drop";
import FileDataContext from "../Contexts/FileData";
// import { geolocated } from "react-geolocated";

const publicIp = require("public-ip");
// const formatToCurrency = (amount) => {
//   return "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
// };

export default class NetAmount extends Component {
  static contextType = FileDataContext;
  constructor(props) {
    super(props);
    this.state = {
      client: window.localStorage.getItem("clientActive"),
      clientCount: 0,
      months: window.localStorage.getItem("months"),
      counter: 1,
      inputs: [],
      tableVisible: true,
      formtable: true,
      visible: true,
      dragVisible: false,
    };
  }

  onChange(index, newValue) {
    const updatedArray = [...this.state.inputs];
    updatedArray[index] = {
      RFC: newValue.name,
      "Ingreso Neto Mensual": parseInt(newValue.value),
    };
    //console.log(updatedArray);
    this.setState({
      inputs: updatedArray,
    });
  }

  download = () => {
    this.setState({ tableVisible: false });

    var array = this.state.inputs;
    let str =
      "kilometraje,year,model,brand,version,color,region,precio_reacondicionamiento_mxn,dias_demanda,cantidad_demanda,compra_oferta3,compra_oferta2,compra_oferta1,venta_color_region,venta" +
      // ",previous_price" +
      "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        
        if (index === "error") {
          break;
        }
        if (index === "version") {
          var change = "";
          if (array[i][index] == null) {
            change = "";
          } else {
            change = array[i][index];
            change = change.replace(",", "");
            var newchar = ".";
            change = change.split(",").join(newchar);

            line += change;
          }
        } else {
          if (array[i][index] == null) {
            line += "";
          } else {
            line += array[i][index];
          }
        }
      }
      str += line + "\r\n";
    }
    //var csvData = new Blob([str], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(
      new Blob([str], {
        type: "data:text/csv;charset=utf-8,",
      }),
      this.state.client + ".csv"
    );
    this.setState({ dragVisible: false });
    this.props.thisHistory.push("/");
  };

  ToHome = () => {
    this.props.thisHistory.push("/");
  };
  convert = () => {
    //get Public IP
    try {
      (async () => {
        window.localStorage.setItem("ip4", await publicIp.v4());
        //window.localStorage.setItem("ip6", await publicIp.v6());
      })();
    } catch (error) {
      console.log(error);
    }
    let ip4 = window.localStorage.getItem("ip4");
    // let ip6 = window.localStorage.getItem("ip6");
    this.setState({ visible: true });
    //activamos la barra de retroceso
    window.localStorage.setItem("uploadActive", true);
    let file = this.context.data;
    let file2 = window.localStorage.getItem("fileBase64_2");
    let user = JSON.parse(window.localStorage.getItem("user"));
    let clientActive = window.localStorage.getItem("clientActive");
    let months = "0";
    let rfcs = window.localStorage.getItem("rfcCounter");
    let date = Date.now();
    let active = false;
    if (file2 != null) {
      active = true;
    }
    let latitude = "";
    let longitude = "";
    try {
      if (this.props.coords) {
        latitude = this.props.coords.latitude;
        longitude = this.props.coords.longitude;
      }
    } catch (err) {
      console.log(err);
    }

    axios
      .post(process.env.REACT_APP_FULL_CALCULATION, {
        file,
        file2,
        user,
        clientActive,
        rfcs,
        months,
        date,
        active,
        latitude,
        longitude,
        ip4,
        //ip6,
      })
      .then((res) => {
        window.localStorage.setItem("uuidActive", res.data);
        window.localStorage.setItem("clientActive", clientActive);

        user = JSON.parse(window.localStorage.getItem("user"));
        axios.post(process.env.REACT_APP_LOGIN, { user }).then((res) => {
          window.localStorage.setItem("user", JSON.stringify(res.data));
          window.localStorage.setItem("aa", "true");
        });
        this.fView();
      }).catch((err) => {
        alert(`Error al guardar los datos: ${err}`);
        // ToastsStore.error(`Error al guardar los datos: ${res.error}`, "show");
        this.fView();
      });
  };

  getrfc = () => {
    this.context.setData([]);
    this.context.setViewData([]);

    // let user = JSON.parse(window.localStorage.getItem("user"));
    // let clientActive = window.localStorage.getItem("clientActive");
    // let month = window.localStorage.getItem("months");

    const fileBase64 = window.localStorage.getItem("fileBase64")

    if (!fileBase64) { 
      this.setState({ visible: false });
      this.props.thisHistory.push("/upload");
      return;
    }

    let file = {
      file: fileBase64,
      evaluate: true,
      omit_external_sources: JSON.parse(window.localStorage.getItem("omit_external_sources")) || false,
      //file: `77u/a2lsb21ldHJhamUseWVhcl9tb2RlbCxicmFuZCx2ZXJzaW9uLHRpcG9faW1wdWVzdG8scHJlY2lvX3JlYWNvbmRpY2lvbmFtaWVudG9fbXhuDQo0MDAwMCwyMDIwIFhDNjAsVm9sdm8sIjUgcHRzLiBUNSBNb21lbnR1bSwgMi4wVCwgMjU0IEhQLCBUQTgsIFJBLTE5IiwxLDEwMDANCjQwMDAwLDIwMjAgWEM2MCxWb2x2bywiNSBwdHMuIFQ2IE1vbWVudHVtLCAyLjBULCAyNTQgSFAsIFRBOCwgUkEtMTkiLDEsMTAwMA0KNDEwMDIsMjAyMSBWaXJ0dXMsVm9sa3N3YWdlbiwiNCBwdHMuIDEuNjEsIFRNNS4gYS9hYy4sIFJBLTE1IiwyLDUwMA0KMTA0OTMwLDIwMjAgWWFyaXMsVG95b3RhLCI1IHB0cy4gSEIgQ29yZSAsIENWVCwgYS9hYy4sIFItMTUiLDMsMTQwMA0K`
    };

    const token = process.env.REACT_APP_TOKEN;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      },
    };

    const host = process.env.REACT_APP_FIRST_READ;
    axios.post(`${host}/v2/predictor/b2b`, JSON.stringify(file), config).then(
      (res) => {
        if (res.data) { 
          for (const [, input] of res.data.entries()) {
            if (input && input.kilometraje) {
              input.kilometraje = input.kilometraje.toString().replace(",", "");
            }
            if (input && input.versions && input.versions.length) {
              input.versions.sort((a, b) => a.order - b.order);
            }
            input['selected'] = {
              brand: input.extra_data['brands']?input.extra_data.brands.indexOf(input.brand):undefined,
              model: input.extra_data['models']?input.extra_data.models.indexOf(input.model):undefined,
              version: input['versions']?input.versions.indexOf(input.version):undefined,
            }
          }
        }
        var t = JSON.stringify(res.data);
        this.context.setData(res.data);
        this.context.setViewData(JSON.parse(t));
        this.setState({ inputs: JSON.parse(t) });

        window.localStorage.setItem(
          "rfcCounter",
          this.context.data.length
        );

        this.setState({
          clientCount:
            this.context.data
            .length,
        });
        window.localStorage.setItem("uploadActive", true);
        this.setState({ visible: false });

        this.convert();
      },
      (error) => {
        let error_msg;
        if (error.response && error.response.status === 500) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            error_msg = error.response.data.message
          }
        }
        else { 
          error_msg = error.toString()
        }
        window.localStorage.setItem("aa", false);
        window.localStorage.setItem("errorArchivo", true);
        if (error_msg) { 
          /////////////////         window.localStorage.setItem("aa", false);
          window.localStorage.setItem("lastErrorMessage", error_msg);
        }
        this.setState({ visible: false });
        this.props.thisHistory.push("/upload");
      }
    ).catch((error) => {
      let error_msg;
      if (error.response && error.response.status === 500) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          error_msg = error.response.data.message
        }
      }
      else { 
        error_msg = error.toString()
      }
      window.localStorage.setItem("aa", false);
      window.localStorage.setItem("errorArchivo", true);
      if (error_msg) { 
        /////////////////         window.localStorage.setItem("aa", false);
        window.localStorage.setItem("lastErrorMessage", error_msg);
      }
      this.setState({ visible: false });
      this.props.thisHistory.push("/upload");
    });

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////// ERROR EN EL ARCHIVO ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // window.localStorage.setItem("aa", false);
    // window.localStorage.setItem("errorArchivo", true);
    // this.setState({ visible: false });
    // this.props.thisHistory.push("/upload");
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////// SUBIR ARCHIVO ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    // var t =
    //   '[{"kilometraje":40000,"year_model":"2020 XC60","brand":"Volvo","version":"5 pts. T5 Momentum, 2.0T, 254 HP, TA8, RA-19","color":"negro","precio_reacondicionamiento_mxn":1000,"compra_margen6":574776.4250933332,"compra_margen8":560415.15568,"compra_oferta3":546053.8862666667,"venta":619020.2333333333,"error":null},{"kilometraje":41002,"year_model":"2021 Virtus","brand":"Volkswagen","version":"4 pts. 1.61, TM5. a/ac., RA-15","color":"negro","precio_reacondicionamiento_mxn":500,"compra_margen6":null,"compra_margen8":null,"compra_oferta3":null,"venta":null,"error":"Data was not found in the training dataset."},{"kilometraje":104930,"year_model":"2020 Yaris","brand":"Toyota","version":"5 pts. HB Core , CVT, a/ac., R-15","color":"rojo","precio_reacondicionamiento_mxn":1400,"compra_margen6":165675.4745484401,"compra_margen8":161502.7126436782,"compra_oferta3":157329.9507389163,"venta":175256,"error":null}]';
    // window.localStorage.setItem("rfcData", t);
    // this.setState({ inputs: JSON.parse(t) });
    // window.localStorage.setItem(
    //   "rfcCounter",
    //   JSON.parse(window.localStorage.getItem("rfcData")).length
    // );

    // this.setState({
    //   clientCount: JSON.parse(window.localStorage.getItem("rfcData")).length,
    // });
    // window.localStorage.setItem("uploadActive", true);
    // this.setState({ visible: false });

    // this.convert();
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  };

  componentDidMount() {
    this.getrfc();
    //window.localStorage.setItem("viewData", "[]");
  }

  fView = () => {
    this.props.thisHistory.push("/client-detail");
  };

  //cuando se va el usuario
  componentWillUnmount() {}

  render() {
    return this.state.visible === true ? (
      <React.Fragment>
        <div className="container-fluid px-0">
          <div className="container mt-80">
            <LoadingScreen type="loading" />
          </div>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="container-fluid px-0">
          <div className="container mt-80">
            <div className="row mt-30">
              <div className="col-12">
                <NavLink
                  className="sidenavbutton"
                  tag={RouterNavLink}
                  to="/upload"
                  exact
                >
                  <i className="fas fa-arrow-left fs-25"></i>
                </NavLink>
                {/* <p className="title">Importe neto</p> */}
                <p className="subtitle">{this.state.client}</p>
                {/* <p>
                  <b>{this.state.clientCount}</b> Unidades encontradas
                </p> */}
                {/* <p>
                  Proyección a <b>{this.state.months}</b> meses
                </p> */}
                {/* <p>
                  A continuación ingresa el importe neto de cada <b>RFC</b>, en
                  caso de que un <b>RFC</b> no cuente con su importe neto no se
                  tomará en cuenta para el siguiente proceso, existen 2 formas
                  de de capturar el importe neto.
                </p>
                <p>
                  1. Desde esta pantalla, llenar el campo <b>Importe</b> neto
                </p>
                <p>
                  2. Descargar este documento y llenar el importe neto con el
                  programa de tu preferencia por ejemplo Excel, cuando termines,
                  regresa a esta pantalla y sube el documento
                </p> */}
              </div>
            </div>
            <div className="row flex-md-row-reverse">
              <div className="col-12 col-md-3">
                {/* {!this.props.isGeolocationAvailable ? (
                  <div>Tu explorador no soporta la Geolocalización</div>
                ) : !this.props.isGeolocationEnabled ? (
                  <Button className="btn -primary w-200 mt-50">
                    Activa la ubicación
                  </Button>
                ) : this.props.coords ? (
                  <Button
                    className="btn -primary w-100 mt-50"
                    onClick={this.convert}
                  >
                    CONTINUAR
                  </Button>
                ) : (
                  console.log()
                )} */}
                <Button
                  className="btn -primary w-100 mt-50"
                  onClick={this.convert}
                >
                  CONTINUAR
                </Button>
              </div>
              <div className="col-12 col-md-3">
                <Button
                  className="btn -secondary w-100 mt-50"
                  onClick={this.download}
                >
                  DESCARGAR_____
                </Button>
              </div>
            </div>
            {this.state.dragVisible === true ? (
              <div className="row mt-30">
                <div className="col-12">
                  <DragDrop isActive={true}></DragDrop>
                </div>
              </div>
            ) : (
              <Label />
            )}

            {this.state.tableVisible === false ? (
              <Label />
            ) : (
              <div className="row">
                <div className="col-12">
                  <table className="w-200 mt-30">
                    <thead>
                      <tr>
                        <th>Kilometraje</th>
                        <th>Year</th>
                        <th>Model</th>
                        <th>Brand</th>
                        <th>Versión</th>
                        <th>Color</th>
                        <th>Región</th>
                        <th>PR-MXN</th>
                        <th>Días Demanda</th>
                        <th>Cantidad Demanda</th>
                        <th>CM_9</th>
                        <th>CM_11</th>
                        <th>CM_13</th>
                        <th>venta</th>
                        {/* <th>Precio plataforma anterior</th> */}
                        {/* <th>Importe Neto</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.context.data.map(
                        (input, index) => (
                          <tr key={index}>
                            <td>{input.kilometraje}</td>
                            <td>{input.year}</td>
                            <td>{input.model}</td>
                            <td>{input.brand}</td>
                            <td>{input.version}</td>
                            <td>{input.color}</td>
                            <td>{input.region}</td>
                            <td>{input.precio_reacondicionamiento_mxn}</td>
                            <td>{input.dias_demanda}</td>
                            <td>{input.cantidad_demanda}</td>
                            <td>{input.compra_oferta3}</td>
                            <td>{input.compra_oferta2}</td>
                            <td>{input.compra_oferta1}</td>
                            <td>{input.venta_atributos}</td>
                            <td>{input.venta}</td>
                            {/* <td>{input.seminuevos_price}</td> */}
                            {/* <td>
                              <div className="text-field" key={index.rank}>
                                <input
                                  type="number"
                                  key={input}
                                  name={input.rank}
                                  placeholder="Importe neto"
                                  onChange={(e) =>
                                    this.onChange(index, e.target)
                                  }
                                />
                                <span className="bar"></span>
                              </div>
                            </td> */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
////////////////////////////////////////////
////// version con paginador  ////////////////////
////////////////////////////////////////

// import React, { Component } from "react";
// import axios from "axios";
// import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";
// import { NavLink } from "../../node_modules/reactstrap";
// import { NavLink as RouterNavLink } from "react-router-dom";
// import { Button, Label } from "reactstrap";
// import FileSaver from "file-saver";
// import DragDrop from "../Components/Drag-n-drop/Drag-n-drop";
// import { geolocated } from "react-geolocated";
// import ReactPaginate from "react-paginate";
// import "../Components/Pagination/styles.css";

// const publicIp = require("public-ip");

// class NetAmount extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       client: window.localStorage.getItem("clientActive"),
//       clientCount: 0,
//       months: window.localStorage.getItem("months"),
//       counter: 1,
//       inputs: [],
//       tableVisible: true,
//       visible: true,
//       dragVisible: false,
//       //pagination
//       offset: 0,
//       data: [],
//       perPage: 15,
//       currentPage: 0,
//     };
//     this.handlePageClick = this.handlePageClick.bind(this);
//   }

//   receivedData() {
//     const data = JSON.parse(window.localStorage.getItem("viewData"));
//     const slice = data.slice(
//       this.state.offset,
//       this.state.offset + this.state.perPage
//     );

//     const postData = slice.map((input, index) => (
//       <tr key={input.RFC}>
//         <td>{input.RFC}</td>
//         <td>
//           <div className="text-field" key={index.RFC}>
//             <input
//               type="number"
//               key={input}
//               name={input.RFC}
//               placeholder="Importe neto"
//               onChange={(e) => this.onChange(index, e.target)}
//             />
//             <span className="bar"></span>
//           </div>
//         </td>
//       </tr>
//     ));

//     this.setState({
//       pageCount: Math.ceil(data.length / this.state.perPage),
//       postData,
//     });
//   }

//   handlePageClick = (e) => {
//     const selectedPage = e.selected;
//     const offset = selectedPage * this.state.perPage;

//     this.setState(
//       {
//         currentPage: selectedPage,
//         offset: offset,
//       },
//       () => {
//         this.receivedData();
//       }
//     );
//   };

//   componentDidMount() {
//     this.getrfc();
//     this.receivedData();
//   }

//   onChange(index, newValue) {
//     const updatedArray = [...this.state.inputs];
//     updatedArray[index] = {
//       RFC: newValue.name,
//       "Ingreso Neto Mensual": parseInt(newValue.value),
//     };
//     //console.log(updatedArray);
//     this.setState({
//       inputs: updatedArray,
//     });
//   }

//   download = () => {
//     this.setState({ tableVisible: false });

//     var array = this.state.inputs;
//     let str = "RFC,Ingreso Neto Mensual\r\n";
//     for (let i = 0; i < array.length; i++) {
//       let line = "";
//       for (let index in array[i]) {
//         if (line !== "") line += ",";
//         line += array[i][index];
//       }
//       str += line + "\r\n";
//     }
//     var csvData = new Blob([str], { type: "text/csv;charset=utf-8;" });
//     FileSaver.saveAs(
//       csvData,
//       this.state.client + "_" + this.state.months + ".csv"
//     );

//     this.setState({ dragVisible: true });
//   };

//   convert = () => {
//     //get Public IP
//     try {
//       (async () => {
//         window.localStorage.setItem("ip4", await publicIp.v4());
//         //window.localStorage.setItem("ip6", await publicIp.v6());
//       })();
//     } catch (error) {
//       console.log(error);
//     }
//     let ip4 = window.localStorage.getItem("ip4");
//     // let ip6 = window.localStorage.getItem("ip6");
//     this.setState({ visible: true });
//     //activamos la barra de retroceso
//     window.localStorage.setItem("uploadActive", true);
//     let file = window.localStorage.getItem("fileBase64");
//     let file2 = window.localStorage.getItem("fileBase64_2");
//     let user = JSON.parse(window.localStorage.getItem("user"));
//     let clientActive = window.localStorage.getItem("clientActive");
//     let months = window.localStorage.getItem("months");
//     let rfcs = this.state.inputs;
//     let date = Date.now();
//     let active = false;
//     if (file2 != null) {
//       active = true;
//     }
//     let latitude = "";
//     let longitude = "";
//     try {
//       latitude = this.props.coords.latitude;
//       longitude = this.props.coords.longitude;
//     } catch (err) {
//       console.log(err);
//     }
//     axios
//       .post(process.env.REACT_APP_FULL_CALCULATION, {
//         file,
//         file2,
//         user,
//         clientActive,
//         rfcs,
//         months,
//         date,
//         active,
//         latitude,
//         longitude,
//         ip4,
//         //ip6,
//       })
//       .then((res) => {
//         window.localStorage.setItem("uuidActive", res.data);
//         window.localStorage.setItem("clientActive", clientActive);

//         user = JSON.parse(window.localStorage.getItem("user"));
//         axios.post(process.env.REACT_APP_LOGIN, { user }).then((res) => {
//           window.localStorage.setItem("user", JSON.stringify(res.data));
//           window.localStorage.setItem("aa", "true");
//         });
//         this.fView();
//       });
//   };

//   getrfc = () => {
//     window.localStorage.setItem("rfcData", "[]");

//     let file = window.localStorage.getItem("fileBase64");
//     let user = JSON.parse(window.localStorage.getItem("user"));
//     let clientActive = window.localStorage.getItem("clientActive");
//     let month = window.localStorage.getItem("months");

//     axios
//       .post(process.env.REACT_APP_FIRST_CALCULATION, {
//         file,
//         user,
//         clientActive,
//         month,
//       })
//       .then(
//         (res) => {
//           //guardando los RFCS

//           window.localStorage.setItem("rfcData", res.data);
//           this.setState({ inputs: JSON.parse(res.data) });
//           // console.log(this.state.inputs);
//           // console.log("Número de RFC encontrados");
//           // console.log(
//           //   "Net-amount -> rfcData counter -> " +
//           //     JSON.parse(window.localStorage.getItem("rfcData")).length
//           // );

//           window.localStorage.setItem(
//             "rfcCounter",
//             JSON.parse(window.localStorage.getItem("rfcData")).length
//           );

//           this.setState({
//             clientCount: JSON.parse(window.localStorage.getItem("rfcData"))
//               .length,
//           });
//           window.localStorage.setItem("uploadActive", true);
//           this.setState({ visible: false });
//         },
//         (error) => {
//           if (error.response.status === 500) {
//             window.localStorage.setItem("aa", false);
//             this.setState({ visible: true });
//           }
//         }
//       );
//   };

//   fView = () => {
//     this.props.thisHistory.push("/client-detail");
//   };

//   //cuando se va el usuario
//   componentWillUnmount() {}

//   render() {
//     return this.state.visible === true ? (
//       <React.Fragment>
//         <div className="container-fluid px-0">
//           <div className="container mt-80">
//             <LoadingScreen type="loading" />
//           </div>
//         </div>
//       </React.Fragment>
//     ) : (
//       <React.Fragment>
//         <div className="container-fluid px-0">
//           <div className="container mt-80">
//             <div className="row mt-30">
//               <div className="col-12">
//                 <NavLink
//                   className="sidenavbutton"
//                   tag={RouterNavLink}
//                   to="/upload"
//                   exact
//                 >
//                   <i className="fas fa-arrow-left fs-25"></i>
//                 </NavLink>
//                 <p className="title">Importe neto</p>
//                 <p className="subtitle">{this.state.client}</p>
//                 <p>
//                   <b>{this.state.clientCount}</b> RFC únicos encontrados
//                 </p>
//                 <p>
//                   Proyección a <b>{this.state.months}</b> meses
//                 </p>
//                 <p>
//                   A continuación ingresa el importe neto de cada <b>RFC</b>, en
//                   caso de que un <b>RFC</b> no cuente con su importe neto no se
//                   tomará en cuenta para el siguiente proceso, existen 2 formas
//                   de de capturar el importe neto.
//                 </p>
//                 <p>
//                   1. Desde esta pantalla, llenar el campo <b>Importe</b> neto
//                 </p>
//                 <p>
//                   2. Descargar este documento y llenar el importe neto con el
//                   programa de tu preferencia por ejemplo Excel, cuando termines,
//                   regresa a esta pantalla y sube el documento
//                 </p>
//               </div>
//             </div>
//             <div className="row flex-md-row-reverse">
//               <div className="col-12 col-md-3">
//                 {/* {!this.props.isGeolocationAvailable ? (
//                   <div>Tu explorador no soporta la Geolocalización</div>
//                 ) : !this.props.isGeolocationEnabled ? (
//                   <Button className="btn -primary w-200 mt-50">
//                     Activa la ubicación
//                   </Button>
//                 ) : this.props.coords ? (
//                   <Button
//                     className="btn -primary w-100 mt-50"
//                     onClick={this.convert}
//                   >
//                     CONTINUAR
//                   </Button>
//                 ) : (
//                   console.log()
//                 )} */}
//                 <Button
//                   className="btn -primary w-100 mt-50"
//                   onClick={this.convert}
//                 >
//                   CONTINUAR
//                 </Button>
//               </div>
//               <div className="col-12 col-md-3">
//                 <Button
//                   className="btn -secondary w-100 mt-50"
//                   onClick={this.download}
//                 >
//                   DESCARGAR
//                 </Button>
//               </div>
//             </div>
//             {this.state.dragVisible === true ? (
//               <div className="row mt-30">
//                 <div className="col-12">
//                   <DragDrop isActive={true}></DragDrop>
//                 </div>
//               </div>
//             ) : (
//               <Label />
//             )}

//             {this.state.tableVisible === false ? (
//               <Label />
//             ) : (
//               <div className="row">
//                 <div className="col-12">
//                   <table className="w-100 mt-30">
//                     <thead>
//                       <tr>
//                         <th>RFC</th>
//                         <th>Importe Neto</th>
//                       </tr>
//                     </thead>
//                     <tbody>{this.state.postData}</tbody>
//                   </table>
//                   <ReactPaginate
//                     previousLabel={"<"}
//                     nextLabel={">"}
//                     breakLabel={"......."}
//                     breakClassName={"break-me"}
//                     pageCount={this.state.pageCount}
//                     marginPagesDisplayed={2}
//                     pageRangeDisplayed={5}
//                     onPageChange={this.handlePageClick}
//                     containerClassName={"pagination"}
//                     subContainerClassName={"pages pagination"}
//                     activeClassName={"active"}
//                   />
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </React.Fragment>
//     );
//   }
// }

// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: true,
//   },
//   userDecisionTimeout: 10000,
// })(NetAmount);

import React, { Component } from "react";
import LandingImg from "../Images/landing-img.svg";

class Landing extends Component {
  render() {
    return (
      <div className="container-fluid px-0">
        <div className="container-fluid d-flex flex-column flex-md-row px-0 mt-80">
          <div className="container">
            <div className="row mx-0 mt-80">
              <div className="col-12">
                <p className="title -purple">Automatizador de ISR</p>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
                <image src={LandingImg} alt="office image" />
              </div>
              <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
                <p className="minititle -landing text-center">
                  Apple pie candy sesame snaps. Tart pudding sugar plum I love
                  oat cake apple pie wafer chocolate ice cream.
                </p>
                <p className="text-right mt-30">
                  Marshmallow candy cupcake. Caramels lollipop cupcake sweet
                  tiramisu pudding. Sweet roll brownie chocolate cake powder
                  bear pudding.
                </p>
              </div>
            </div>

            <div className="row mt-80 d-flex justify-content-center">
              <div className="col-10 col-md-3">
                <a href="/" className="btn -primary">
                  INICIAR SESIÓN
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;

import React, { Component } from "react";
import axios from "axios";
import history from "../utils/history";
import { NavLink } from "../../node_modules/reactstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      description: "",
      rol: "",
      loading: false,
      gerente: false,
      admin: false,
      data: JSON.parse(window.localStorage.getItem("getUsers")),
      dataAdmin: [],
      dataGerentes: [],
    };
  }

  componentDidMount() {
    window.localStorage.removeItem("setName");
    window.localStorage.removeItem("setFirstName");
    window.localStorage.removeItem("setLastName");
    window.localStorage.removeItem("setEmail");
    window.localStorage.removeItem("setPassword");
    window.localStorage.removeItem("setPasswordConfirm");
    window.localStorage.removeItem("setRol");
    window.localStorage.removeItem("setGerente");
    window.localStorage.removeItem("setAdmin");
  }
  createUser = () => {
    var flag = false;

    var given_name = window.localStorage.getItem("setName");
    var family_name =
      window.localStorage.getItem("setFirstName") +
      " " +
      window.localStorage.getItem("setLastName");
    var email = window.localStorage.getItem("setEmail");
    var password = window.localStorage.getItem("setPassword");
    var password2 = window.localStorage.getItem("setPasswordConfirm");
    //creators
    var new_user_rol = window.localStorage.getItem("setRol");
    var gerente = window.localStorage.getItem("setGerente");
    var admin = window.localStorage.getItem("setAdmin");

    var creator = JSON.parse(window.localStorage.user).email;
    var creator_rol = JSON.parse(localStorage.user).rol;

    var levelA = JSON.parse(localStorage.user).levelA;
    var levelB = JSON.parse(localStorage.user).levelB;
    var levelC = JSON.parse(localStorage.user).levelC;
    var levelD = JSON.parse(localStorage.user).levelD;
    var levelArol = JSON.parse(localStorage.user).levelArol;
    var levelBrol = JSON.parse(localStorage.user).levelBrol;
    var levelCrol = JSON.parse(localStorage.user).levelCrol;
    var levelDrol = JSON.parse(localStorage.user).levelDrol;

    if (given_name === null) {
      ToastsStore.error("EL campo nómbre está vacio");
      flag = true;
    }
    if (family_name === null) {
      ToastsStore.error("El apellido está vacio");
      flag = true;
    }

    if (JSON.parse(localStorage.user).rol === "SuperAdmin") {
      if (localStorage.setRol === "Gerente" && admin === null) {
        ToastsStore.error("Selecciona un Administrador");
        flag = true;
      }
    }

    if (JSON.parse(localStorage.user).rol === "SuperAdmin") {
      if (localStorage.setRol === "Ejecutivo" && gerente === null) {
        ToastsStore.error("Selecciona un Gerente");
        flag = true;
      }
      if (localStorage.setRol === "Ejecutivo" && admin === null) {
        ToastsStore.error("Selecciona un Administrador");
        flag = true;
      }
    }

    if (JSON.parse(localStorage.user).rol === "Administrador") {
      if (localStorage.setRol === "Ejecutivo" && gerente === null) {
        ToastsStore.error("Selecciona un Gerente");
        flag = true;
      }
    }

    // if (
    //   gerente === null &&
    //   localStorage.setRol === "Ejecutivo" &&
    //   (JSON.parse(localStorage.user).rol === "Administrador" ||
    //     JSON.parse(localStorage.user).rol === "SuperAdmin")
    // ) {
    //   ToastsStore.error("Selecciona un Gerente");
    //   flag = true;
    // }

    // if (
    //   admin === null &&
    //   localStorage.setRol === "Ejecutivo" &&
    //   (JSON.parse(localStorage.user).rol === "Administrador" ||
    //     JSON.parse(localStorage.user).rol === "SuperAdmin")
    // ) {
    //   ToastsStore.error("Selecciona un Administrador");
    //   flag = true;
    // }

    // if (
    //   admin === null &&
    //   localStorage.setRol === "Gerente" &&
    //   JSON.parse(localStorage.user).rol === "SuperAdmin"
    // ) {
    //   ToastsStore.error("Selecciona un Administrador");
    //   flag = true;
    // }

    if (new_user_rol === null) {
      ToastsStore.error("El Rol está vacio");
      flag = true;
    }
    if (password !== password2) {
      ToastsStore.error("El password no coincide");
      flag = true;
    }

    if (password2 === null) {
      ToastsStore.error("Debes confirmar el password");
      flag = true;
    }

    if (password === null) {
      ToastsStore.error("El password está vacio");
      flag = true;
    }

    if (email === null || !email.includes("@")) {
      ToastsStore.error("El Email tiene un formato incorrecto");
      flag = true;
    }

    if (flag === false) {
      this.setState({
        loading: true,
      });
      try {
        axios
          .post(process.env.REACT_APP_URL_CREATE_USER, {
            given_name,
            family_name,
            email,
            creator,
            creator_rol,
            password,
            new_user_rol,
            levelA,
            levelB,
            levelC,
            levelD,
            levelArol,
            levelBrol,
            levelCrol,
            levelDrol,
            gerente,
            admin,
          })
          .then((res) => {
            if (res.data.message) {
              ToastsStore.error(res.data.message);
              this.setState({
                loading: false,
              });
            } else {
              ToastsStore.success("Se creó correctamente");
              axios.get(process.env.REACT_APP_URL_GET_ALL_USERS).then((res) => {
                window.localStorage.setItem(
                  "getUsers",
                  JSON.stringify(res.data)
                );
                history.push("/user-admin");
              });
              window.localStorage.removeItem("setName");
              window.localStorage.removeItem("setFirstName");
              window.localStorage.removeItem("setLastName");
              window.localStorage.removeItem("setEmail");
              window.localStorage.removeItem("setPassword");
              window.localStorage.removeItem("setPasswordConfirm");
              window.localStorage.removeItem("setRol");
              window.localStorage.removeItem("setGerente");
              window.localStorage.removeItem("setAdmin");
            }
          });
      } catch (error) {
        ToastsStore.error(error);
        this.setState({
          loading: false,
        });
      }
    }
  };
  setName = (event) => {
    window.localStorage.setItem("setName", event.target.value);
  };
  setFirstName = (event) => {
    window.localStorage.setItem("setFirstName", event.target.value);
  };
  setLastName = (event) => {
    window.localStorage.setItem("setLastName", event.target.value);
  };
  setEmail = (event) => {
    window.localStorage.setItem("setEmail", event.target.value);
  };
  setPassword = (event) => {
    window.localStorage.setItem("setPassword", event.target.value);
  };
  setPasswordConfirm = (event) => {
    window.localStorage.setItem("setPasswordConfirm", event.target.value);
  };

  setRol = (event) => {
    this.setState({
      rol: event.target.value,
    });

    this.setState({
      description: this.renderSwitch(event.target.value),
    });
    window.localStorage.setItem("setRol", event.target.value);
    /////////////////////////////////
    //  SuperAdmin - Ejecutivo  ////
    /////////////////////////////////

    // if (
    //   JSON.parse(localStorage.user).rol === "superAdmin" &&
    //   event.target.value === "Ejecutivo"
    // ) {
    //   var data = [];
    //   this.state.data.forEach(function (item) {
    //     if (

    //       JSON.parse(localStorage.user).email === item.user_metadata.creator ||
    //       JSON.parse(localStorage.user).email === item.email
    //     ) {
    //       data.push(item);
    //     }
    //   });
    //   this.setState({
    //     dataAdmin: data,
    //   });

    //   this.setState({
    //     gerente: true,
    //   });
    // }

    if (
      JSON.parse(localStorage.user).rol === "SuperAdmin" &&
      event.target.value === "Ejecutivo"
    ) {
      var data = [];
      //guarda buscando admins
      this.state.data.forEach(function (item) {
        if (item.user_metadata.levelArol === "SuperAdmin") {
          if (item.user_metadata.rol === "Administrador") {
            data.push(item);
          }
        }
      });

      window.localStorage.setItem(
        "setAdmin",
        JSON.parse(localStorage.user).email
      );
      window.localStorage.setItem(
        "setGerente",
        JSON.parse(localStorage.user).email
      );
      this.setState({
        dataAdmin: data,
      });

      this.setState({
        gerente: true,
        admin: true,
      });
    }

    if (
      JSON.parse(localStorage.user).rol === "SuperAdmin" &&
      event.target.value === "Gerente"
    ) {
      window.localStorage.removeItem("setGerente");

      data = [];
      this.state.data.forEach(function (item) {
        if (item.user_metadata.levelArol === "SuperAdmin") {
          if (item.user_metadata.rol === "Administrador") {
            data.push(item);
          }
        }
      });
      this.setState({
        dataAdmin: data,
      });
      window.localStorage.setItem(
        "setAdmin",
        JSON.parse(localStorage.user).email
      );
      this.setState({
        admin: true,
        gerente: false,
      });
    }

    if (
      JSON.parse(localStorage.user).rol === "SuperAdmin" &&
      event.target.value === "Administrador"
    ) {
      window.localStorage.removeItem("setGerente");
      window.localStorage.removeItem("setAdmin");
      this.setState({
        gerente: false,
        admin: false,
      });
    }
    ////////////////////////////////////// [super admin ends] //////////////////
    //////////////////////administrador//////////////////
    if (
      JSON.parse(localStorage.user).rol === "Administrador" &&
      event.target.value === "Gerente"
    ) {
      window.localStorage.removeItem("setGerente");
      this.setState({
        gerente: false,
      });
    }
    if (
      JSON.parse(localStorage.user).rol === "Administrador" &&
      event.target.value === "Ejecutivo"
    ) {
      data = [];
      this.state.data.forEach(function (item) {
        if (
          JSON.parse(localStorage.user).email === item.user_metadata.creator
        ) {
          if (item.user_metadata.rol === "Gerente") {
            data.push(item);
          }
        }
      });

      window.localStorage.setItem(
        "setGerente",
        JSON.parse(localStorage.user).email
      );
      this.setState({
        dataGerentes: data,
      });

      this.setState({
        gerente: true,
        admin: false,
      });
    }
    //////////////////////termina administrador//////////////////
  };

  // if (
  //   (event.target.value === "Ejecutivo" ||
  //     event.target.value === "Nomina interna") &&
  //   (JSON.parse(localStorage.user).rol === "Administrador" ||
  //     JSON.parse(localStorage.user).rol === "SuperAdmin")
  // ) {
  //   this.setState({
  //     gerente: true,
  //     admin: true,
  //   });
  // } else {
  //   this.setState({
  //     gerente: false,
  //     admin: false,
  //   });
  // }

  setGerente = (event) => {
    window.localStorage.setItem("setGerente", event.target.value);
  };

  setAdmin = (event) => {
    let data = [];
    window.localStorage.setItem("setAdmin", event.target.value);

    this.state.data.forEach(function (item) {
      if (JSON.parse(localStorage.user).email === event.target.value) {
        if (item.user_metadata.rol === "Gerente") {
          data.push(item);
        }
      } else {
        if (event.target.value === item.user_metadata.levelB) {
          data.push(item);
        }
      }
    });

    //validar gerente
    // if (data.length === 0) {
    //   console.log(data)
    //   window.localStorage.setItem(
    //     "setGerente",
    //     JSON.parse(localStorage.user).email
    //   );
    // }

    this.setState({
      dataGerentes: data,
    });
  };

  renderSwitch(param) {
    switch (param) {
      case "SuperAdmin":
        return "Control total en la plataforma";

      case "Administrador":
        return "Permisos para crear, modificar y eliminar usuarios y permisos.";

      case "Ejecutivo":
        return "Permisos para crear clientes y subir archivos.";

      case "Gerente":
        return "Permisos para crear, modificar y eliminar usuarios y permisos. Puede crear clientes y subir archivos.";

      default:
        return "No hay info";
    }
  }

  render() {
    return (
      <div className="container-fluid d-flex flex-column flex-md-row px-0 mt-navbar">
        <ToastsContainer
          store={ToastsStore}
          position={"top_center"}
          lightBackground
        />
        {this.state.loading === true ? (
          <div className="container">
            <LoadingScreen type={"loading-users"} />
          </div>
        ) : (
          <div className="container">
            <div className="row mt-30">
              <div className="col-12">
                <NavLink
                  className="sidenavbutton"
                  tag={RouterNavLink}
                  to="/user-admin"
                  exact
                >
                  <i className="fas fa-arrow-left fs-25"></i>
                </NavLink>
                <p className="title">Agregar usuario</p>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-12">
                <p className="minititle">Datos del usuario</p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-4 mt-30">
                <div className="text-field">
                  <input type="text" onChange={this.setName} required />
                  <span className="bar"></span>
                  <label>Nombre</label>
                </div>
              </div>

              <div className="col-12 col-lg-4 mt-30">
                <div className="text-field">
                  <input type="text" onChange={this.setFirstName} required />
                  <span className="bar"></span>
                  <label>Apellido Paterno</label>
                </div>
              </div>

              <div className="col-12 col-lg-4 mt-30">
                <div className="text-field">
                  <input type="text" onChange={this.setLastName} required />
                  <span className="bar"></span>
                  <label>Apellido Materno</label>
                </div>
              </div>

              <div className="col-12 col-lg-4 mt-30">
                <div className="text-field">
                  <input type="email" onChange={this.setEmail} required />
                  <span className="bar"></span>
                  <label>Correo Electrónico</label>
                </div>
              </div>

              <div className="col-12 col-lg-4 mt-30">
                <div className="text-field">
                  <input type="password" onChange={this.setPassword} required />
                  <span className="bar"></span>
                  <label>Password</label>
                </div>
              </div>
              <div className="col-12 col-lg-4 mt-30">
                <div className="text-field">
                  <input
                    type="password"
                    onChange={this.setPasswordConfirm}
                    required
                  />
                  <span className="bar"></span>
                  <label>Confirmar Password</label>
                </div>
              </div>
            </div>

            <div className="row mt-40">
              <div className="col-12">
                <p className="minititle">Rol dentro de la plataforma</p>
                <p>Selecciona un rol para el nuevo usuario:</p>
                <p>
                  <b>{this.state.rol}</b> {this.state.description}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-4 mt-20">
                <div className="text-field">
                  <select
                    //defaultValue={this.state.selectValue}
                    onChange={this.setRol}
                    type="email"
                  >
                    <option key={0} value="none" selected disabled>
                      Seleccionar
                    </option>
                    {JSON.parse(window.localStorage.getItem("getRoles")).map(
                      (input, index) => (
                        <option
                          key={index}
                          id={input.description}
                          value={input.name}
                        >
                          {input.name}
                        </option>
                      )
                    )}
                  </select>
                  <span className="bar"></span>
                  <label>Rol</label>
                </div>
              </div>
              {this.state.admin === true ? (
                <div className="col-12 col-lg-4 mt-20">
                  <div className="text-field">
                    <select onChange={this.setAdmin} type="email">
                      <option key={0} value="none" selected disabled>
                        Seleccionar
                      </option>
                      {this.state.dataAdmin.map((input, index) => (
                        <option
                          key={index}
                          id={input.description}
                          value={input.email}
                        >
                          {input.name} - {input.email}
                        </option>
                      ))}
                    </select>
                    <span className="bar"></span>
                    <label>Administrador Responsable</label>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {this.state.gerente === true ? (
                <div className="col-12 col-lg-4 mt-20">
                  <div className="text-field">
                    <select onChange={this.setGerente} type="email">
                      <option key={0} value="none" selected disabled>
                        Seleccionar
                      </option>

                      {this.state.dataGerentes.map((input, index) => (
                        <option
                          key={index}
                          id={input.description}
                          value={input.email}
                        >
                          {input.name} - {input.email}
                        </option>
                      ))}
                    </select>
                    <span className="bar"></span>
                    <label>Gerente Responsable</label>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="row mt-40">
              <div className="col-12 col-lg-3">
                <button
                  onClick={this.createUser}
                  className="btn -secondary w-100"
                >
                  Agregar usuario
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CreateUser;

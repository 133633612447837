import React, { Component } from "react";
import TableDetail from "../Components/Table-detail/Table-detail";
import { NavLink as RouterNavLink } from "react-router-dom";
import { NavLink } from "../../node_modules/reactstrap";
// import axios from "axios";
import FileSaver from "file-saver";
import FileDataContext from "../Contexts/FileData";
import MultiSelect from "../Components/MultiSelect/MultiSelect";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

// function getUrlDocument() {
//   let uuid = window.localStorage.getItem("uuidActive");
//   let user = JSON.parse(window.localStorage.getItem("user"));
//   let client = window.localStorage.getItem("client");
//   axios
//     .post(process.env.REACT_APP_URL_DOWNLOAD, { client, user, uuid })
//     .then((response) => {
//       window.open(response.data, "_blank");
//     });
// }

const columnOptions = [
  {value: "kilometraje", label: "Kilometraje"},
  {value: "year", label: "Año"},
  {value: "brand", label: "Marca"},
  {value: "model", label: "Modelo"},
  {value: "version", label: "Versión"},
  {value: "color", label: "Color"},
  {value: "region", label: "Region"},
  {value: "precio_reacondicionamiento_mxn", label: "Costo reacondicionamiento"},
  {value: "dias_demanda", label: "Días Prom. en Venderse"},
  {value: "cantidad_demanda", label: "Cantidad de Autos Vendidos"},
  {value: "compra_oferta3", label: "Precio Compra Oferta 3"},
  {value: "compra_oferta2", label: "Precio Compra Oferta 2"},
  {value: "compra_oferta1", label: "Precio Compra Oferta 1"},
  {value: "venta_color_region", label: "Precio Venta con Color y Region"},
  {value: "venta", label: "Precio Venta"},
  {value: "resolución", label: "Resolución"},
  {value: "precio_resolución", label: "Precio Resolución"},
  {value: "precio_venta_autometrica", label: "Precio Venta Autométrica"},
  {value: "precio_compra_autometrica", label: "Precio Compra Autométrica"},
  {value: "marca_autometrica", label: "Marca Autométrica"},
  {value: "modelo_autometrica", label: "Modelo Autométrica"},
  {value: "version_autometrica", label: "Versión Autométrica"},
  {value: "error", label: "Error"},
  {value: "warnings", label: "Warnings"},
];

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span style={{fontSize: 10}}>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();
const customStyles = {
  // option: (provided, state) => ({
  //   ...provided,
  //   borderBottom: '1px dotted pink',
  //   color: state.isSelected ? 'red' : 'blue',
  //   // padding: 20,
  // }),
  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
}

function download(array, columns) {
  // var array = JSON.parse(window.localStorage.getItem("ExportData"));
  let str = (columns ? columns.map((e) => e.value).join(",") : "") + "\r\n";
  // let str =
  //   "kilometraje,year,brand,model,version,color,region,precio_reacondicionamiento_mxn,dias_demanda,cantidad_demanda,compra_oferta3,compra_oferta2,compra_oferta1,venta_color_region,venta" +
  //   ",resolución" +
  //   ",precio_resolución" +
  //   ",precio_venta_autometrica" +
  //   ",precio_compra_autometrica" +
  //   ",marca_autometrica" +
  //   ",modelo_autometrica" +
  //   ",version_autometrica" +
  //   // ",previous_price" +
  //   ",error" +
  //   "\r\n";
  let kilometraje;
  let version;
  let version_trim = "";
  let year;
  let model;
  let brand;
  let compra_oferta3;
  let compra_oferta2;
  let compra_oferta1;
  let venta_atributos;
  let venta;
  // let seminuevos_price;
  let color;
  let region;
  let precio_reacondicionamiento_mxn;
  let dias_demanda;
  let cantidad_demanda;
  let resolution;
  let resolution_data;
  let autometrica_details;
  let warnings_str;
  let error;

  for (let i = 0; i < array.length; i++) {

    kilometraje =
    year =
    brand =
    model =
    version =
    color =
    region =
    precio_reacondicionamiento_mxn =
    dias_demanda =
    cantidad_demanda =
    compra_oferta3 =
    compra_oferta2 =
    compra_oferta1 =
    venta_atributos =
    venta =
    resolution =
    resolution_data =
    autometrica_details =
    warnings_str =
    // seminuevos_price =
    error = '';

    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";
      
      if (index === "error" && array[i]["error"]) {
        year = array[i]["year"];
        model = array[i]["model"];
        brand = array[i]["brand"];
        error = array[i]["error"];
        // for (let index2 in array[i]) { 
        // }
      } else if (index === "versions") {
        var change = "";
        if (array[i][index] == null) {
          change = "";
        } else {
          if (index === "versions") {
            if (!array[i][index]) { 
              year = array[i]["year"];
              model = array[i]["model"];
              brand = array[i]["brand"];
              error = 'No elements';
              continue;
            }
            let version_item = array[i][index]
            change = version_item["version"] || '';
            change = change.replace(",", "");
            var newchar = ".";
            version = change.split(",").join(newchar);
            if (version) { 
              version_trim = version_item["version_trim"];
              if (version.includes(version_trim) || version_trim === 'Base') {
                version_trim = "";
              } else { 
                version_trim = ` | ${version_trim}`
              }
            }
            year = version_item["year"];
            model = version_item["model"];
            brand = version_item["brand"];
            dias_demanda =
            version_item["dias_demanda"] === undefined
                ? ""
                : parseFloat(version_item["dias_demanda"])
                .toFixed(2);
            cantidad_demanda =
            version_item["cantidad_demanda"] === undefined
                ? ""
                : parseFloat(version_item["cantidad_demanda"])
                .toFixed(2);
            color =
            version_item["color"] === undefined
                ? ""
                : version_item["color"];
            region =
            version_item["region"] === undefined
                ? ""
                : version_item["region"];
            compra_oferta3 =
            version_item["compra_oferta3"] === undefined
                ? ""
                : version_item["compra_oferta3"];
            compra_oferta2 =
              version_item["compra_oferta2"] === undefined
                ? ""
                : version_item["compra_oferta2"];
            compra_oferta1 =
              version_item["compra_oferta1"] === undefined
                ? ""
                : version_item["compra_oferta1"];
            venta_atributos =
              version_item["venta_atributos"] === undefined
                ? ""
                : version_item["venta_atributos"];
            venta =
              version_item["venta"] === undefined
                ? ""
                : version_item["venta"];
            resolution =
              version_item["resolution"] === undefined
                ? ""
                : version_item["resolution"];
            resolution_data =
              version_item["resolution_data"] === undefined
                ? ""
                : version_item["resolution_data"];
            autometrica_details =
              version_item["autometrica_details"] === undefined
                ? ""
                : version_item["autometrica_details"];
                warnings_str =
              version_item["warnings_str"] === undefined
                ? ""
                : version_item["warnings_str"];
            // seminuevos_price =
            //   version_item["seminuevos_price"] === undefined
            //     ? ""
            //     : version_item["seminuevos_price"];
          }
        }
      } else {
        if (array[i][index] == null) {
          line += "";
        } else {
          if (index === "kilometraje") {
            kilometraje = array[i][index];
          } else if (index === "color") {
            color = array[i][index];
          } else if (index === "region") {
            region = array[i][index];
          } else if (index === "precio_reacondicionamiento_mxn") {
            precio_reacondicionamiento_mxn = array[i][index];
          } else if (index === "dias_demanda") {
            dias_demanda = array[i][index];
          } else if (index === "cantidad_demanda") {
            cantidad_demanda = array[i][index];
          } else if (index === "resolution") {
            resolution = array[i][index];
          } else if (index === "resolution_data") {
            resolution_data = array[i][index];
          } else if (index === "autometrica_details") {
            autometrica_details = array[i][index];
          } else if (index === "warnings_str") {
            warnings_str = array[i][index];
          }
        }
      }
    }
    /* eslint-disable no-loop-func */
    str += columns.map((e) => { 
      switch (e.value) { 
        case 'kilometraje':
          return kilometraje;
        case 'year':
          return year;
        case 'brand':
          return brand;
        case 'model':
          return model;
        case 'version':
          return version + version_trim;
        case 'color':
          return color;
        case 'region':
          return region;
        case 'precio_reacondicionamiento_mxn':
          return precio_reacondicionamiento_mxn;
        case 'dias_demanda':
          return dias_demanda;
        case 'cantidad_demanda':
          return cantidad_demanda;
        case 'compra_oferta3':
          return compra_oferta3;
        case 'compra_oferta2':
          return compra_oferta2;
        case 'compra_oferta1':
          return compra_oferta1;
        case 'venta_color_region':
          return venta_atributos;
        case 'venta':
          return venta;
        case 'resolución':
          return resolution;
        case 'precio_resolución':
          return (resolution_data.price || "");
        case 'precio_venta_autometrica':
          return (autometrica_details.sell || "");
        case 'precio_compra_autometrica':
          return (autometrica_details.buy || "");
        case 'marca_autometrica':
          return `"${autometrica_details.brand || ""}"`;
        case 'modelo_autometrica':
          return `"${autometrica_details.modelYear_model || ""}"`;
        case 'version_autometrica':
          return `"${autometrica_details.version || ""}"`;
        case 'error':
          return error;
        case 'warnings':
          return warnings_str;
        default:
          return "";
      }
    }).join(",")+"\r\n";

    // str +=
    //   kilometraje +
    //   "," +
    //   year +
    //   "," +
    //   brand +
    //   "," +
    //   model +
    //   "," +
    //   version + version_trim +
    //   "," +
    //   color +
    //   "," +
    //   region +
    //   "," +
    //   precio_reacondicionamiento_mxn +
    //   "," +
    //   dias_demanda +
    //   "," +
    //   cantidad_demanda +
    //   "," +
    //   compra_oferta3 +
    //   "," +
    //   compra_oferta2 +
    //   "," +
    //   compra_oferta1 +
    //   "," +
    //   venta_atributos +
    //   "," +
    //   venta +
    //   "," +
    //   resolution +
    //   "," +
    //   (resolution_data.price || "") +
    //   "," +
    //   (autometrica_details.sell || "") +
    //   "," +
    //   (autometrica_details.buy || "") +
    //   "," +
    //   `"${autometrica_details.brand || ""}"` +
    //   "," +
    //   `"${autometrica_details.modelYear_model || ""}"` +
    //   "," +
    //   `"${autometrica_details.version || ""}"` +
    //   // "," +
    //   // seminuevos_price +
    //   "," +
    //   error +
    //   "\r\n";
  }
  //var csvData = new Blob([str], { type: "text/csv;charset=utf-8;" });
  FileSaver.saveAs(
    new Blob([str], {
      type: "data:text/csv;charset=utf-8,",
    }),
    window.localStorage.getItem("clientActive") + ".csv"
  );
}

class ClientDetail extends Component {
  static contextType = FileDataContext
  state = {
    columnsSelected: JSON.parse(window.localStorage.getItem("downloadColumnsSelected")) || columnOptions,
    client: window.localStorage.getItem("clientActive"),
    date: window.localStorage.getItem("dateActive"),
    months: window.localStorage.getItem("months"),
    rfcCounter: window.localStorage.getItem("rfcCounter"),
    formtable: true,
  };

  handleColumnsSelectedChange = selected => {
    this.setState({
      columnsSelected: selected
    });
    window.localStorage.setItem("downloadColumnsSelected", JSON.stringify(selected));
  };

  onChange(index, newValue) {
    const updatedArray = [...this.state.inputs];
    updatedArray[index] = {
      RFC: newValue.name,
      "Ingreso Neto Mensual": parseInt(newValue.value),
    };
    //console.log(updatedArray);
    this.setState({
      inputs: updatedArray,
    });
  }

  // getDocuments() {
  //   let uuid = window.localStorage.getItem("uuidActive");
  //   //console.log("uuid -> activo -> ");
  //   let user = JSON.parse(window.localStorage.getItem("user"));
  //   let client = window.localStorage.getItem("client");

  //   axios
  //     .post(process.env.REACT_APP_DOWNLOAD, { client, user, uuid })
  //     .then((response) => {
  //       var array = JSON.parse(
  //         response.data.replace("b'", "").replace("'", "")
  //       );

  //       let str =
  //         "mensual, catorcenal,quincenal,semanal,diaria,Tarifa conveniente,RFC, NOMBRE \r\n";
  //       for (let i = 0; i < array.length; i++) {
  //         let line = "";
  //         for (let index in array[i]) {
  //           if (line !== "") line += ",";
  //           line += array[i][index];
  //         }
  //         str += line + "\r\n";
  //       }
  //       var csvData = new Blob([str], { type: "text/csv;charset=utf-8;" });
  //       FileSaver.saveAs(
  //         csvData,
  //         (uuid = uuid.replace(user.email + "/", "") + ".csv")
  //       );
  //     });
  // }

  componentDidMount() {
    window.localStorage.removeItem("fileBase64");
    window.localStorage.removeItem("fileBase64_2");
  }
  render() {
    // const { selectedOption } = this.state;

    return (
      <div className="container-fluid px-0">
        <div className="container-fluid d-flex flex-column flex-md-row px-0 mt-80">
          <div className="container client-detail mt-container">
            <div className="row mt-30">
              <div className="col-12 ">
                {window.localStorage.getItem("uploadActive") === "true" ? (
                  console.log()
                ) : (
                  <NavLink
                    className="sidenavbutton"
                    tag={RouterNavLink}
                    to="/client-story"
                    exact
                  >
                    <i className="fas fa-arrow-left fs-25"></i>
                  </NavLink>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-12 ml-12">
                <p className="title mb-0">{this.state.client}</p>

                <div className="subtitle">
                  {new Intl.DateTimeFormat("es-ES", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(Date.now())}
                  <br />
                  <p className="subtitle">
                    {this.state.rfcCounter} Automóviles disponibles
                  </p>
                </div>
                {/* <p className="subtitle">{this.state.rfcCounter}</p> */}
              </div>
            </div>

            <div className="row flex-row-reverse mt-30">
              <div className="col-12 col-lg-3">
                <button
                  className="btn -primary w-100"
                  onClick={() => {
                    download(this.context.exportData, this.state.columnsSelected);
                  }}
                >
                  DESCARGAR
                </button>
              </div>
              <div className="col-10 col-lg-9">
                <MultiSelect
                  placeholder="Seleccionar columnas..."
                  allOption={{
                    label: "Todas las columnas",
                    value: "*"
                  }}
                  styles={customStyles}
                options={columnOptions}
                  isMulti
                  isSearchable
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={this.handleColumnsSelectedChange}
                allowSelectAll={true}
                value={this.state.columnsSelected}
                />
              </div>
            </div>
            <TableDetail />
          </div>
        </div>
      </div>
    );
  }
}

export default ClientDetail;

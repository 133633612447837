import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker as map } from "@fortawesome/free-solid-svg-icons";
import { NavbarBrand } from "../../../node_modules/reactstrap";
import { Infotip } from "@trendmicro/react-tooltip";

const Marker = ({ dateTime, ip4, uuid, nombre, rol }: any) => (
  <NavbarBrand>
    <Infotip
      placement="left"
      content={() => {
        return (
          <div>
            Fecha de acceso: {dateTime}
            <br />
            IP de acceso: {ip4}
          </div>
        );
      }}
    >
      <FontAwesomeIcon icon={map} size={"1x"} color={"red"} />
    </Infotip>
  </NavbarBrand>
);

const Map = (props: any) => {
  // const [center, setCenter] = useState({ lat: 19.5231744, lng: -99.04128 });
  // const [zoom, setZoom] = useState(10);
  const [center] = useState({ lat: 19.5231744, lng: -99.04128 });
  const [zoom] = useState(10);

  //const places = JSON.parse(window.localStorage.getItem("markers")).map(
  console.log(props.pines);
  const places = props.pines.map((place) => {
    return (
      <Marker
        key={place.dateTime}
        lat={place.latitude}
        lng={place.longitude}
        dateTime={place.dateTime}
        uuid={place.uuid}
        ip4={place.ip4}
      />
    );
  });

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {places}
      </GoogleMapReact>
    </div>
  );
};

export default Map;

import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import FileDataContext from "../Contexts/FileData";

type State = {
  options: [{ [string]: string }],
  value: string | void,
};

const save = (str) => {
  window.localStorage.setItem("clientActive", str);
};
const camelSentence = (str) => {
  return (" " + str)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
};

const defaultOptions = [];

// const Checkbox = ({ label, value, onChange }) => {
//   return (
//     <label>
//       <input type="checkbox" checked={value} onChange={onChange} />
//       {label}
//     </label>
//   );
// };

class Upload extends Component<*, State> {
  static contextType = FileDataContext;
  constructor(props, context) {
    super(props);
    this.state = {
      newUpload: false,
      monthsDisable: false,
      clientDisable: true,
      fileDisable: true,
      clientActive: null,
      isLoading: false,
      options: defaultOptions,
      value: undefined,

      fileActive: false,
    };
    context.setData([]);
  }

  createOption =
    JSON.parse(window.localStorage.user).rol === "Administrador" ||
    JSON.parse(window.localStorage.user).rol === "SuperAdmin"
      ? (label: string) => ({
          //value: label.toLowerCase().replace(/\W/g, ""),
          super_admin: JSON.parse(window.localStorage.user).levelA,
          admin: JSON.parse(window.localStorage.user).email,
          gerente: JSON.parse(window.localStorage.user).email,
          creator: JSON.parse(window.localStorage.user).email,
          value: label,
          label,
          active: "true",
          environment: process.env.REACT_APP_URL_ENVIRONMENT,
        })
      : JSON.parse(window.localStorage.user).rol === "Gerente"
      ? (label: string) => ({
          //value: label.toLowerCase().replace(/\W/g, ""),
          super_admin: JSON.parse(window.localStorage.user).levelA,
          admin: JSON.parse(window.localStorage.user).levelB,
          gerente: JSON.parse(window.localStorage.user).email,
          creator: JSON.parse(window.localStorage.user).email,
          value: label,
          label,
          active: "true",
          environment: process.env.REACT_APP_URL_ENVIRONMENT,
        })
      : (label: string) => ({
          //value: label.toLowerCase().replace(/\W/g, ""),
          super_admin: JSON.parse(window.localStorage.user).levelA,
          admin: JSON.parse(window.localStorage.user).levelB,
          gerente: JSON.parse(window.localStorage.user).levelC,
          creator: JSON.parse(window.localStorage.user).email,
          value: label,
          label,
          active: "true",
          environment: process.env.REACT_APP_URL_ENVIRONMENT,
        });

  getMonths = (event) => {
    if (event.target.value === "") {
      this.setState({
        monthsDisable: false,
      });
    } else {
      this.setState({
        monthsDisable: false,
      });
    }
    window.localStorage.setItem("months", parseFloat("0"));
  };

  componentDidMount() {
    this.getClients();
  }

  noClient() {
    this.setState({
      clientDisable: true,
    });
    this.setState({ clientActive: null });
    window.localStorage.setItem("clientActive", "");
  }
  yesClient = (newValue) => {
    save(newValue.label);
    this.setState({
      clientDisable: false,
    });
    this.setState({ clientActive: newValue.label });
  };

  handleChange = (newValue: any, actionMeta: any) => {
    //console.group("Value Changed");
    newValue === null ? this.noClient() : this.yesClient(newValue);
    //console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    this.setState({ value: newValue });
  };

  handleCreate = (inputValue: any) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      const { options } = this.state;
      const newOption = this.createOption(camelSentence(inputValue));
      //console.log(newOption);
      this.saveClient(newOption);
      window.localStorage.setItem(
        "clientActive",
        camelSentence(newOption.label)
      );

      this.setState({ clientDisable: false });
      this.setState({
        isLoading: false,
        options: [...options, newOption],
        value: newOption,
      });
    }, 1000);
  };

  getClients = () => {
    axios.post(process.env.REACT_APP_GET_CLIENTS).then((res) => {
      let responseData = JSON.parse(res.data);

      responseData.map((client) => {
        if (JSON.parse(localStorage.user).rol === "SuperAdmin") {
          const { options } = this.state;
          const newOption = this.createOption(client.label);
          this.setState({
            options: [...options, newOption],
          });
          return "";
        }
        if (JSON.parse(localStorage.user).rol === "Administrador") {
          if (client.admin === JSON.parse(localStorage.user).email) {
            const { options } = this.state;
            const newOption = this.createOption(client.label);
            this.setState({
              options: [...options, newOption],
            });
            return "";
          }
        }

        if (JSON.parse(localStorage.user).rol === "Gerente") {
          if (client.gerente === JSON.parse(localStorage.user).email) {
            const { options } = this.state;
            const newOption = this.createOption(client.label);
            this.setState({
              options: [...options, newOption],
            });
            return "";
          }
        } else {
          if (client.creator === JSON.parse(localStorage.user).email) {
            const { options } = this.state;
            const newOption = this.createOption(client.label);
            this.setState({
              options: [...options, newOption],
            });
            return "";
          }
        }
        return "";
      });
    });
    //generar aviso de error en el archivo
    if (window.localStorage.getItem("errorArchivo") === "true") {
      let error = window.localStorage.getItem("lastErrorMessage");
      if (error) {
        ToastsStore.error(
          `${error}`, 300000, "show"
        );
        window.localStorage.removeItem("lastErrorMessage")
        } else { 
          ToastsStore.error(
            `El archivo contiene errores, por favor corrige los datos`, 10000, "show"
          );
      }
      window.localStorage.setItem("errorArchivo", false);
    }
  };

  saveClient(client) {
    axios.post(process.env.REACT_APP_SAVE_CLIENTS, { client }).then((res) => {
      console.log();
    });
  }

  render() {
    window.localStorage.removeItem("client");
    window.localStorage.removeItem("fileBase64");
    window.localStorage.removeItem("fileBase64_2");

    window.localStorage.removeItem("uploadActive");

    window.localStorage.removeItem("rfcCounter");

    window.localStorage.removeItem("uuidActive");
    window.localStorage.removeItem("date");
    window.localStorage.removeItem("ejecutivoActive");

    const { isLoading, options, value } = this.state;

    const handleChangeStatus = ({ file }, status) => {
      if (status === "removed") {
        window.localStorage.removeItem("fileBase64");
        this.setState({ fileDisable: true });
        this.setState({ fileActive: true });
      }

      if (status === "done") {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        // var toRead = "";
        reader.onloadend = () => {
          //console.log(reader.result);
          window.localStorage.setItem(
            "fileBase64",
            reader.result
              .replace("data:application/octet-stream;base64,", "")
              .replace("data:application/vnd.ms-excel;base64,", "")
              .replace("data:text/csv;base64,", "")
              .replace(
                "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
                ""
              )
          );
        };
        this.setState({ fileActive: true });
        this.setState({ fileDisable: false });
      } else if (status === "aborted") {
      }
    };

    return (
      <div className="container-fluid px-0">
        <div className="container mt-navbar">
          <div className="row mt-30">
            <div className="col-12 col-md-8">
              <p className="title mt-50">Cargar nuevo documento</p>
            </div>
          </div>
          {this.state.file}
          <div className="row mt-30">
            <div className="col-12 col-lg-8">
              <CreatableSelect
                isClearable
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={this.handleChange}
                onCreateOption={this.handleCreate}
                options={options}
                value={value}
                placeholder="Crea un nombre para tu cliente o selecciona uno existente"
              />
            </div>
          </div>

          {/* <div className="row my-30">
            <div className="col-12 col-lg-8">
              <div className="text-field">
                <input type="number" onChange={this.getMonths} required />
                <span className="bar"></span>
                <label>Cantidad de meses a proyectar</label>
              </div>
            </div>
          </div> */}
          <div className="row mt-30">
            <div className="col-12 col-lg-8">
              <Dropzone
                accept=".csv"
                //accept=".csv,.xls,.xlsx"
                onChangeStatus={handleChangeStatus}
                inputContent="   Arrastra tu archivo aquí o da click en esta área y selecciónalo"
                disabled={(files) =>
                  files.some((f) =>
                    [
                      "preparando",
                      "Obteniendo los parametros de subida",
                      "Subiendo",
                    ].includes(f.meta.status)
                  )
                }
                maxFiles={1}
                multiple={false}
                canCancel={true}
                maxSizeBytes={100000000}
                styles={{
                  dropzoneActive: { borderColor: "green" },
                }}
              />
              {/* <Checkbox
                  label="Incluir los precios de la plataforma anterior (mayor tiempo de espera)"
                  value={this.props.checkedOne}
                  onChange={this.props.handleChangeOne}
                /> */}
            </div>
          </div>
          <div className="row mt-30 ">
            <div className="col-12 col-lg-3">
              <button
                disabled={
                  this.state.clientDisable === false &&
                  this.state.monthsDisable === false &&
                  this.state.fileDisable === false
                    ? false
                    : true
                }
                className="btn -secondary w-100"
                onClick={() =>
                  window.localStorage.getItem("fileBase64") === null
                    ? alert("favor de cargar de nuevo el archivo")
                    : this.props.thisHistory.push("/net-amount")
                }
              >
                CONTINUAR
              </button>
            </div>
          </div>

          {/* <div className="row mt-30 ">
            <div className="col-12">
              {this.state.newUpload === false ? (
                <button
                  className="btn -primary ml-auto"
                  onClick={() => this.props.thisHistory.push("/net-amount")}
                >
                  CAPTURAR INGRESOS NETOS
                </button>
              ) : (
                <button
                  className="btn -primary ml-auto"
                  onClick={() => this.props.thisHistory.push("/net-amount")}
                >
                  CALCULO DIRECTO
                </button>
              )}
            </div>
          </div> */}

          {/* <div className="row">
          <div className="col-12">
            <Download button2="DESCARGAR" button1="CANCELAR" buttonLabel="Ejemplo de modal"></Download>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Delete button2="ELIMINAR" button1="CANCELAR" buttonLabel="Ejemplo de modal"></Delete>
          </div>
        </div> */}
        </div>
        {/* <button onClick={() => ToastsStore.success("Hey, you just clicked!", "show")}>Click me</button> */}
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_CENTER}
          lightBackground
        />
      </div>
    );
  }
}

// const UploadFunction = (props) => {
//   const [checkedOne, setCheckedOne] = React.useState(!(JSON.parse(window.localStorage.getItem("omit_external_sources")) || false));
//   const handleChangeOne = () => {
//     window.localStorage.setItem("omit_external_sources", checkedOne);
//     setCheckedOne(!checkedOne);
//   };
//   return (
//     <Upload
//       checkedOne={ checkedOne}
//       setCheckedOne={setCheckedOne}
//       handleChangeOne={handleChangeOne}
//       {...props}
//     />
//   )
// }

export default Upload;

import React, { Component } from "./../../../node_modules/react";
import { NavLink as RouterNavLink } from "./../../../node_modules/react-router-dom";
import { NavLink } from "./../../../node_modules/reactstrap";
import axios from "axios";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@trendmicro/react-tooltip";
import "@trendmicro/react-tooltip/dist/react-tooltip.css";

const getHelp = () => window.open(process.env.REACT_APP_MANUAL_ISR, "_blank");

class Sidenav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ejecutivo: false,
      gerente: false,
      documentacion: false,
      active: "true",
    };
  }

  getRoles = () => {
    axios.get(process.env.REACT_APP_URL_GET_ROLES).then((res) => {
      try {
        if (JSON.parse(window.localStorage.user).rol === "SuperAdmin") {
          res.data.splice(
            res.data.findIndex((x) => x.name === "SuperAdmin"),
            1
          );
        }
        if (JSON.parse(window.localStorage.user).rol === "Administrador") {
          res.data.splice(
            res.data.findIndex((x) => x.name === "Administrador"),
            1
          );
          res.data.splice(
            res.data.findIndex((x) => x.name === "SuperAdmin"),
            1
          );
        }
        if (JSON.parse(window.localStorage.user).rol === "Gerente") {
          res.data.splice(
            res.data.findIndex((x) => x.name === "Administrador"),
            1
          );
          res.data.splice(
            res.data.findIndex((x) => x.name === "Gerente"),
            1
          );
          res.data.splice(
            res.data.findIndex((x) => x.name === "SuperAdmin"),
            1
          );
        }
        window.localStorage.setItem("getRoles", JSON.stringify(res.data));
        this.setState({
          roles: JSON.stringify(res.data),
        });
      } catch (err) {}
    });
  };

  getRol() {
    try {
      let _user = this.props.thisUser.sub;
      let rol = null;
      axios
        .post(process.env.REACT_APP_URL_GET_ROL_BY_USER, { _user })
        .then((res) => {
          if (res.data.length === 0) {
            window.localStorage.setItem("Rol", "Ejecutivo");
          } else {
            for (const s of res.data[0]['sources']) { 
              if (s['source_type'] === 'ROLE') {
                rol = s['source_name'];
              }
            }
            if (res.data.find(e => e.permission_name === 'read:documentation')) {
              this.setState({
                documentacion: true,
              });
            }

            if (
              rol === "Administrador" ||
              rol === "SuperAdmin" ||
              rol === "Gerente"
            ) {
              axios.get(process.env.REACT_APP_URL_GET_ALL_USERS).then((res) => {
                window.localStorage.setItem(
                  "getUsers",
                  JSON.stringify(res.data)
                );
                window.localStorage.setItem("Rol", rol);

                this.setState(
                  {
                    visible: true,
                  },
                  () => {
                    this.getRoles();
                  }
                );

                this.setState({
                  gerente: true,
                });

                return;
              });
            } else {
              this.setState({
                ejecutivo: true,
              });
            }
          }
        });
      //verificar
      setTimeout(() => {
        this.setState({
          active: JSON.parse(window.localStorage.user).active,
        });
      }, 3000);
    } catch (er) {}
  }

  componentDidMount() {
    try {
      this.getRol();
    } catch (er) {
      console.log(er);
    }
  }

  render() {
    return (
      <div className="side-nav">
        {this.state.gerente === true && this.state.active === "true" ? (
          <div className="inner">
            <NavLink
              className="sidenavbutton"
              tag={RouterNavLink}
              to="/"
              exact
              activeClassName="router-link-exact-active"
            >
              <i className="fas fa-address-book"></i>
            </NavLink>
            <NavLink
              className="sidenavbutton"
              tag={RouterNavLink}
              to="/upload"
              exact
              activeClassName="router-link-exact-active"
            >
              <i className="fas fa-folder-plus"></i>
            </NavLink>
            <NavLink
              className="sidenavbutton"
              tag={RouterNavLink}
              to="/user-admin"
              exact
              activeClassName="router-link-exact-active"
            >
              <i className="fas fa-user-plus"></i>
            </NavLink>
            {
              this.state.documentacion === true ? (
              <NavLink className="sidenavbutton" onClick={getHelp}>
                <Tooltip content="Presiona el bóton para descargar el manual de usuario">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
                </NavLink>
              ): (<div></div>)
            }
          </div>
        ) : (
          <div />
        )}
        {this.state.ejecutivo === true && this.state.active === "true" ? (
          <div className="inner">
            <NavLink
              className="sidenavbutton"
              tag={RouterNavLink}
              to="/"
              exact
              activeClassName="router-link-exact-active"
            >
              <i className="fas fa-address-book"></i>
            </NavLink>
            <NavLink
              className="sidenavbutton"
              tag={RouterNavLink}
              to="/upload"
              exact
              activeClassName="router-link-exact-active"
            >
              <i className="fas fa-folder-plus"></i>
            </NavLink>
            {
              this.state.documentacion === true ? (
                <NavLink
                className="sidenavbutton"
                activeClassName="router-link-exact-active"
              >
                <Tooltip content="Presiona el bóton para descargar el manual de usuario">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
              </NavLink>
              ): (<div></div>)
            }
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default Sidenav;

import React, { createContext, useState } from "react"

export const FileDataContext = createContext()

export const FileDataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [exportData, setExportData] = useState([]);
  return (
    <FileDataContext.Provider value={{data,setData,viewData,setViewData,exportData,setExportData}}>
      {children}
    </FileDataContext.Provider>
  );
};

export const FileDataConsumer = ({ children }) => {
  return (
    <FileDataContext.Consumer>
      {children}
    </FileDataContext.Consumer>
  );
};

export default FileDataContext;

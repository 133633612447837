import React, { Component } from "react";
import { NavLink } from "./../../../node_modules/reactstrap";
import { NavLink as RouterNavLink } from "./../../../node_modules/react-router-dom";
import axios from "axios";

class ClientCard extends Component {
  deleteDocument(e) {
    let email = e.data.email;
    let client = e.client;
    let option = "client";

    try {
      axios
        .post(process.env.REACT_APP_URL_GET_DELETE, { email, client, option })
        .then((res) => {
          if (res.data.deleted) {
            window.location.reload(true);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      //hidden={this.props.isHidden}
      <div className={`client-card ${this.props.customClass}`}>
        <div className="rowwrapper">
          {this.props.text === 0 ? (
            <NavLink>{this.props.client}</NavLink>
          ) : JSON.parse(window.localStorage.user).rol !== "Ejecutivo" ? (
            <NavLink
              tag={RouterNavLink}
              to="/client-story"
              onClick={(e) => {
                window.localStorage.setItem("client", this.props.client);
              }}
            >
              Cliente: {this.props.client}
              <p>
                Atiende : {this.props.data.user_name}
                <br />
                Rol : {this.props.data.rol}
              </p>
            </NavLink>
          ) : (
            <NavLink
              tag={RouterNavLink}
              to="/client-story"
              onClick={(e) => {
                window.localStorage.setItem("client", this.props.client);
              }}
            >
              {this.props.client}
            </NavLink>
          )}
          {/* {JSON.parse(localStorage.user).rol === "Administrador" ||
          JSON.parse(localStorage.user).rol === "SuperAdmin" ||
          JSON.parse(localStorage.user).rol === "Gerente" ? (
            <button className="btn -iconred ml-auto">
              <i className="fas fa-trash"></i>
            </button>
          ) : (
            <button className="btn -iconred ml-auto" />
          )} */}
          <button
            className="btn -iconred ml-auto"
            onClick={(e) => {
              this.deleteDocument(this.props);
            }}
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
        <div className="rowwrapper">
          <i className="far fa-file-alt mr-2"></i>
          <p className="text mb-0">{this.props.text}</p>
        </div>
      </div>
    );
  }
}

export default ClientCard;

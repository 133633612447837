import React, { Component } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

export default class DragDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileActive: false,
    };
  }

  render() {
    // const Preview = ({ meta }) => {
    //   const { name, percent, status } = meta;
    //   return (
    //     <span
    //       style={{
    //         alignSelf: "flex-start",
    //         margin: "10px 3%",
    //         fontFamily: "Helvetica",
    //       }}
    //     >
    //       {name}, {Math.round(percent)}%, {status}
    //     </span>
    //   );
    // };

    // const toast = (innerHTML) => {
    //   const el = document.getElementById("toast");
    //   el.innerHTML = innerHTML;
    //   el.className = "show";
    //   setTimeout(() => {
    //     el.className = el.className.replace("show", "");
    //   }, 3000);
    // };

    

    const handleChangeStatus = ({ file }, status) => {
      if (status === "removed") {
        window.localStorage.removeItem("fileBase64_2");
      }
      if (status === "done") {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          window.localStorage.setItem("fileBase64_2", reader.result);
        };
      } else if (status === "aborted") {
      }
    };

    return (
      <div>
        <Dropzone
          accept=".csv"          
          onChangeStatus={handleChangeStatus}
          inputContent="   Arrastra tu archivo aquí o da click en esta área y selecciónalo"
          disabled={(files) =>
            files.some((f) =>
              [
                "preparando",
                "Obteniendo los parametros de subida",
                "Subiendo",
              ].includes(f.meta.status)
            )
          }
          maxFiles={1}
          multiple={false}
          canCancel={true}
          maxSizeBytes={100000000}
          styles={{
            dropzoneActive: { borderColor: "green" },
          }}
        />
        {/* <div className="row mt-30 ">
          <div className="col-12">
            {this.state.fileActive === false ? (
              <Label />
            ) : (
              this.props.isActive === false ?
              <button
                className="btn -primary ml-auto"
                onClick={() => this.props.myHistory.push("/net-amount")}
              >
                CONTINUAR
              </button>
              :<Label />
            )}
          </div>
        </div> */}
      </div>
    );
  }
}

import React, { Component } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";
import { NavLink } from "../../node_modules/reactstrap";
import FileDataContext from "../Contexts/FileData"

// function getUrlDocument(uuid) {
//   let user = JSON.parse(window.localStorage.getItem("user"));
//   let client = window.localStorage.getItem("client");
//   axios
//     .post(process.env.REACT_APP_URL_DOWNLOAD, { client, user, uuid })
//     .then((response) => {
//       window.open(response.data, "_blank");
//     });
// }

// function getDocuments(uuid) {
//   let user = JSON.parse(window.localStorage.getItem("user"));
//   let client = window.localStorage.getItem("client");
//   axios
//     .post(process.env.REACT_APP_DOWNLOAD, { client, user, uuid })
//     .then((response) => {
//       var array = JSON.parse(response.data.replace("b'", "").replace("'", ""));

//       let str =
//         "mensual, catorcenal,quincenal,semanal,diaria,Tarifa conveniente,RFC, NOMBRE \r\n";
//       for (let i = 0; i < array.length; i++) {
//         let line = "";
//         for (let index in array[i]) {
//           if (line !== "") line += ",";
//           line += array[i][index];
//         }
//         str += line + "\r\n";
//       }
//       var csvData = new Blob([str], { type: "text/csv;charset=utf-8;" });
//       FileSaver.saveAs(
//         csvData,
//         (uuid = uuid.replace(user.email + "/", "") + ".csv")
//       );
//     });
// }

class ClientStory extends Component {
  static contextType = FileDataContext;
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      client: "",
      clients: [],
    };
  }

  deleteDocument(id, item) {
    let email = item.email;
    let client = window.localStorage.getItem("client");
    let uuid = item.uuid;
    let option = "document";

    try {
      axios
        .post(process.env.REACT_APP_URL_GET_DELETE, {
          email,
          client,
          option,
          uuid,
        })
        .then((res) => {
          document.getElementById(id).style.display = "none";
          window.localStorage.setItem("aa", "true");
          // if (res.data.deleted) {
          //   window.location.reload(true);
          // }
        });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.setState({ user: JSON.parse(window.localStorage.getItem("user")) });
    this.setState({
      clients: JSON.parse(window.localStorage.getItem("clients")),
    });
  }
  fView = () => {
    this.props.thisHistory.push("/client-detail");
  };

  render() {
    return (
      <div className="container-fluid px-0">
        {this.state.user === null ? (
          <div></div>
        ) : (
          <div className="container-fluid d-flex flex-column flex-md-row px-0 mt-80">
            <div className="container mt-container">
              <div className="row mt-30">
                <div className="col-12 ">
                  <NavLink
                    className="sidenavbutton"
                    tag={RouterNavLink}
                    to="/"
                    exact
                  >
                    <i className="fas fa-arrow-left fs-25"></i>
                  </NavLink>
                </div>
              </div>

              <div className="row">
                <div className="col-12 ml-12">
                  <p className="title">
                    {window.localStorage.getItem("client")}
                  </p>
                </div>
              </div>

              {/* <div className="row d-flex flex-row-reverse">
                <div className="col-12 col-md-3">
                  <button className="btn -primary">DESCARGAR SELECCIÓN</button>
                </div>

                <div className="col-12 col-md-3">
                  <button className="btn -primary">DESCARGAR TODO</button>
                </div>
              </div> */}

              <div className="row">
                <div className="col-12">
                  {/* <TableStory></TableStory> */}
                  <Table className="table-story">
                    <Thead>
                      <Tr>
                        {JSON.parse(localStorage.user).rol !== "Ejecutivo" ? (
                          <Th>Nombre</Th>
                        ) : (
                          console.log()
                        )}
                        {JSON.parse(localStorage.user).rol !== "Ejecutivo" ? (
                          <Th>Email</Th>
                        ) : (
                          console.log()
                        )}
                        {JSON.parse(localStorage.user).rol !== "Ejecutivo" ? (
                          <Th>Rol</Th>
                        ) : (
                          console.log()
                        )}
                        <Th>Automóviles</Th>
                        <Th>Fecha de creación</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {this.state.clients.map((item) => {
                        if (
                          item.name === window.localStorage.getItem("client")
                        ) {
                          // const sortedActivities = item.documents.sort((a, b) => b.date - a.date)

                          return item.documents.map((item2, rowId) => (
                            <Tr id={item2.uuid} key={rowId} className="table-row mb-0 mt-0">
                              {/* <Td>
                                <input type="checkbox" className="formcheck" />
                                <label className="form-check-label"></label>
                              
                              </Td> */}

                              {JSON.parse(localStorage.user).rol !==
                              "Ejecutivo" ? (
                                <Td>{item2.name}</Td>
                              ) : (
                                console.log()
                              )}
                              {JSON.parse(localStorage.user).rol !==
                              "Ejecutivo" ? (
                                <Td>{item2.email}</Td>
                              ) : (
                                console.log()
                              )}
                              {JSON.parse(localStorage.user).rol !==
                              "Ejecutivo" ? (
                                <Td>{item2.rol}</Td>
                              ) : (
                                console.log()
                              )}

                              <Td>{item2.rfc_counter}</Td>

                              <Td>
                                {new Intl.DateTimeFormat("es-ES", {
                                  year: "numeric",
                                  month: "long",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }).format(item2.date)}
                              </Td>

                              <Td id={item2.uuid}>
                                {JSON.parse(localStorage.user).rol ===
                                  "Administrador" ||
                                JSON.parse(localStorage.user).rol ===
                                  "SuperAdmin" ||
                                JSON.parse(localStorage.user).rol ===
                                  "Gerente" ? (
                                  //boton de borrar ejecutivo
                                  <button
                                    id={item2.uuid}
                                    className="btn -iconred ml-auto"
                                    onClick={(e) =>
                                      this.deleteDocument(e.target.id, item2)
                                    }
                                  >
                                    <i
                                      className="fas fa-trash"
                                      id={item2.uuid}
                                    ></i>
                                  </button>
                                ) : (
                                  <button
                                    id={item2.uuid}
                                    className="btn -iconred ml-auto"
                                    onClick={(e) =>
                                      this.deleteDocument(e.target.id, item2)
                                    }
                                  >
                                    <i
                                      className="fas fa-trash"
                                      id={item2.uuid}
                                    ></i>
                                  </button>
                                )}
                                <button
                                  className="btn -iconprimary ml-auto"
                                  onClick={() => {
                                    window.localStorage.setItem(
                                      "ejecutivoActive",
                                      item2.email
                                    );
                                    window.localStorage.setItem(
                                      "uuidActive",
                                      item2.uuid
                                    );
                                    window.localStorage.setItem(
                                      "clientActive",
                                      window.localStorage.getItem("client")
                                    );
                                    window.localStorage.setItem(
                                      "dateActive",
                                      item2.date
                                    );
                                    window.localStorage.setItem(
                                      "months",
                                      item2.month_projections
                                    );
                                    // console.log(
                                    //   "Client-Story -> item2.rfc_counter -> " +
                                    //     item2.rfc_counter
                                    // );
                                    window.localStorage.setItem(
                                      "rfcCounter",
                                      item2.rfc_counter
                                    );

                                    this.context.setViewData([]);

                                    this.fView();
                                  }}
                                >
                                  <i className="fas fa-eye"></i>
                                </button>
                              </Td>
                            </Tr>
                          ));
                        } else {
                          return null;
                        }
                      })}
                    </Tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ClientStory;

import React, { Component } from "react";

class EditUser extends Component {
  render() {
    return (
      <div className="container-fluid px-0">
        <div className="container-fluid d-flex flex-column flex-md-row px-0 mt-80">
          <div className="container">
            <div className="row mt-30 d-flex justify-content-center">
              <div className="col-12 col-md-8">
                <p className="title d-flex justify-content-center">
                  Editar usuario
                </p>
              </div>
            </div>

            <div className="row mx-0 mt-80 d-flex justify-content-center">
              <div className="col-12 col-md-8">
                <p className="text-high">Nombre actual: Bram Stoker Vlad </p>
                <p className="text-high">
                  Correo electrónico actual: bram.stkr@gmail.com
                </p>
              </div>
            </div>

            <div className="row mx-0 mt-80 d-flex justify-content-center">
              <div className="col-12 col-md-8">
                <p className="text-high">Nuevo nombre:</p>
                <input className="w-100" type="text" id="fname" name="fname" />
              </div>
              <div className="col-12 col-md-8 mt-30">
                <p className="text-high">Nuevo correo electrónico:</p>
                <input className="w-100" type="text" id="lname" name="lname" />
              </div>
            </div>

            <div className="row mx-0 mt-80 d-flex justify-content-center">
              <div className="col-10 px-0 d-flex justify-content-center">
                <button className="btn -primary">GUARDAR CAMBIOS</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;

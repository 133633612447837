// eslint-disable-next-line
import React, { Component, useEffect } from "react";
import IndexLetter from "../Components/Index-letter/Index-letter";
import ClientCard from "../Components/Client-card/Client-card";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";
import axios from "axios";


const Observer = ({ didUpdate = () => { } }) => {
  useEffect(
    () => {
      didUpdate();
    },
    [didUpdate]
  )
  return null // component does not render anything
}
class Agenda extends Component {
  constructor() {
    super();
    this.state = {
      user: [],
      clients: [],
      visible: true,
      search: null,
      client: window.localStorage.clientActive,
      type: "loading-agenda",
      lastcard: "",
      active: "true",
    };
  }
  searchSpace = (event) => {
    let keyword = event.target.value;
    //console.log(keyword);
    this.setState({ search: keyword });
  };

  componentDidMount() {
    window.localStorage.setItem("uploadActive", false);
    if (window.localStorage.aa === "true") {
      this.getUsers(this.props.thisUser);
    }
  }

  getUsers(user) {
    try {
      var rol = "";
      axios.post(process.env.REACT_APP_LOGIN, { user, rol }).then((res) => {
        this.setState({ user: res.data }, () => {
          window.localStorage.setItem(
            "clients",
            JSON.stringify(
              res.data.clients.sort((a, b) => a.name.localeCompare(b.name))
            )
          );
          var cliente = "";
          var data = [];
          var counter = 0;
          JSON.parse(window.localStorage.clients).forEach(function (item) {
            if (cliente !== item.name) {
              cliente = item.name;
              data.push(item);
              counter++;
            } else {
              item.documents.forEach(function (item2) {
                data[counter - 1].documents.push(item2);
              });
            }
            window.localStorage.setItem("clients", JSON.stringify(data));
          });

          if (window.localStorage.clients === "undefined") {
            this.setState({ clients: [] });
            this.setState({
              active: JSON.parse(localStorage.user).active,
            });
          } else {
            window.localStorage.setItem("user", JSON.stringify(res.data));
            if (JSON.parse(window.localStorage.clients).length === 0) {
              window.localStorage.setItem("aa", true);
              this.setState({ type: "home" });
            } else {
              this.setState({ type: "loading" });
              this.setState({ visible: true });
              window.localStorage.setItem("aa", false);
            }
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  setVisible() {
    if (
      this.state.visible === true &&
      window.localStorage.aa !== "true" &&
      JSON.parse(window.localStorage.getItem("user")).active !== "false"
    ) {
      this.setState({ visible: false }, () => { });
      this.forceUpdate();
    }
  }

  render() {
    var letra = "A";
    var counter = 0;
    return (
      <div className="container-fluid px-0">
        <div className="container-fluid d-flex flex-column flex-md-row px-0 mt-navbar">
          <div className="container">
            {this.state.visible === false ? (
              <div className="row mt-30">
                <div className="col-12 col-lg-6">
                  <div className="row">
                    <div className="col-12">
                      <input
                        style={{ width: "30em", height: "3em" }}
                        type="text"
                        placeholder="    Buscar cliente "
                        onChange={(e) => this.searchSpace(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* <Observer value={this.state.visible} didUpdate={this.setVisible.bind(this)}/> */}
            {this.state.visible === true ? (
              window.localStorage.aa === "true" ? (
                <LoadingScreen
                history={this.props.thisHistory}
                type={this.state.type}
                />
                ) : JSON.parse(window.localStorage.getItem("user")).active ===
                "false" ? (
                  <LoadingScreen
                  history={this.props.thisHistory}
                  type={"activeFalse"}
                  />
                  ) : (
                    <Observer didUpdate={this.setVisible.bind(this)}/>
              )
            ) : (
              JSON.parse(window.localStorage.clients)
                .filter((data) => {
                  if (this.state.search == null) return data;
                  else if (
                    data.name
                      .toLowerCase()
                      .includes(this.state.search.toLowerCase())
                  ) {
                    return data;
                  }
                  return "";
                })
                .map((data) => {
                  return (
                    <React.Fragment key={data.name}>
                      <div className="row">
                        <div className="col-12">
                          <div>
                            {counter === 0
                              ? ((letra = data.name.charAt(0)),
                                counter++,
                                (
                                  <IndexLetter
                                    customClass="mt-30"
                                    letter={letra}
                                  />
                                ))
                              : letra === data.name.charAt(0)
                              ? console.log()
                              : ((letra = data.name.charAt(0)),
                                (
                                  <IndexLetter
                                    customClass="mt-30"
                                    letter={letra}
                                  />
                                ))}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <ClientCard
                            data={data}
                            key={data.name}
                            client={data.name}
                            customClass="mt-30"
                            text={data.documents.length}
                          />

                          {/* {window.localStorage.setItem("cardMap", data.name)} */}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Agenda;

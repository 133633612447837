import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import "./styles.css";
import { Tbody, Tr, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import Tag from "../Tag/Tag";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@trendmicro/react-tooltip";
import "@trendmicro/react-tooltip/dist/react-tooltip.css";
import {
  UncontrolledButtonDropdown,
  // Label,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Spinner,
  Button,
} from "reactstrap";
import { ToastsContainer, ToastsStore } from "react-toasts";
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Input,
} from 'reactstrap';
import axios from "axios";
import FileDataContext from "../../Contexts/FileData";

const formatToCurrency = (amount) => {
  var t =
    "$" +
    parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");

  return t === "$NaN" ? "" : t;
};

const formatToPercent = (amount) => {
  var t =
    parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "&,") + '%';

  return t === "NaN%" ? "" : t;
};

const warningMessageTranslation = {
  overpirced: 'El precio del mercado supera el precio de agencia',
  overpriced: 'El precio del mercado supera el precio de agencia',
  high_end_price: 'Auto de gama alta (>625000) con descuento extra del 8%',
}

const fillSaleTooltip = (busca) => { 
  if (!busca) return;
  if (busca.warnings && busca.warnings.length > 0) {
    busca.warnings_str = busca.warnings.map((e) => {
      if (e in warningMessageTranslation)
        return warningMessageTranslation[e];
      else
        return e;
    }).join("\n");
  }
  if (busca.error) {
    busca.sale_tooltip = busca.error;
  } else { 
    busca.sale_tooltip = busca.warnings_str;
  }
}

var slice = [];
const resultColumns = [
  'venta',
  'compra',
  'sale',
  'purchase',
  'demand',
  'warnings',
];

const getDowndownVersion = (o) => { 
  if (o.version) {
    if (o.version.includes(o.version_trim) || o.version_trim === 'Base') {
      return o.version;
    } else {
      return `${o.version} | ${o.version_trim}`
    }
  } else { 
    return "";
  }
}

// function timeout(delay: number) {
//   return new Promise( res => setTimeout(res, delay) );
// }

const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;

class PaginationComponent extends Component {
  static contextType = FileDataContext;
  constructor(props) {
    super(props);
    this.state = {
      modal:false,
      currentPage: 0,
      offset: 0,
      data: [],
      perPage: 5,
      inputs: [],
      resolutions:[
        'ACEPTADA',
        'RECHAZADA VENDEDOR',
        'RECHAZADA COMPRADOR',
      ]
    };
    this.modalPrice = React.createRef()
    this.setResolution = this.setResolution.bind(this);
    this.setAutometrica = this.setAutometrica.bind(this);
    this.resolutionOnKeyUp = this.resolutionOnKeyUp.bind(this);
    this.autometricaOnKeyUp = this.autometricaOnKeyUp.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  setDataToExport() {
    const data = JSON.parse(JSON.stringify(this.context.viewData))
    var _versions_ = data.map((e) => e.versions ? e.versions[
      e.selected && e.selected.version !== -1?e.selected.version:0
    ]:[]);
    _versions_.forEach((_v, _i) => { 
      fillSaleTooltip(_v);
    });
    let _clean = data;
    data.forEach((_e, _i) => {
      _clean[_i].versions = _versions_[_i];
    });
    this.context.setExportData(_clean);
  }

  async receivedData(state = {}) {
    let autometricaBrands = await this.getAutometricaBrands();
    const data = JSON.parse(JSON.stringify(this.context.viewData));
    state = { ...this.state, ...state }
    slice = data.slice(
      state.offset,
      state.offset + state.perPage
    );

    this.setState({
      ...state,
      autometrica: {
        brands: autometricaBrands
      },
      slice: slice,
      pageCount: Math.ceil(data.length / this.state.perPage),
      inputs: data.map((e, _index) => {
        let versions = (e.versions && e.versions.length)?e.versions:[
          Object.assign({}, e)
        ]
        let version_index = 0;
        for (const [i, v] of versions.entries()) {
          if (v.version === e.version) { 
            version_index = i;
            break;
          }
        }
        let result = {
          ...versions[version_index],
        }
        if (e.resolution)
        {
          result.resolution = e.resolution;
        }
        if (e.resolution_data)
        {
          result.resolution_data = e.resolution_data;
        }
        if (e.updated_by)
        {
          result.updated_by = e.updated_by;
        }
        if (e.updated)
        {
          result.updated = e.updated;
        }
        if (e.autometrica_details)
        {
          result.autometrica_details = e.autometrica_details;
        }
        if (e.warnings)
        {
          result.warnings = e.warnings;
        }
        if (e.error_code) {
          let notFoundField = '';
          switch (e.error_code) {
            case 'version_not_found':
              notFoundField = 'version';
              break;
            case 'model_not_found':
              notFoundField = 'model';
              result.version = '';
              break;
            case 'brand_not_found':
              notFoundField = 'brand';
              result.model = '';
              result.version = '';
              break;
            default:
              break;
          }
          if (notFoundField) {
            result[notFoundField] = '';
            result.error = `${e.error}: ${e[notFoundField]}`;
            for (const [k,] of Object.entries(result)) {
              if (resultColumns.some(x => k.includes(x))) {
                result[k] = '';
              }
            }
          }
        }
        fillSaleTooltip(result);
        return result
      }),
    });
    this.setDataToExport();
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.receivedData(
      {
        currentPage: selectedPage,
        offset: offset,
      }
    );
  };

  async getAutometricaBrands() {
    // ############################### GET BRANDS ############################## //
    const token = process.env.REACT_APP_TOKEN;
    let query = {
      // brand: busca.brand
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      },
      params: query
    };
    const host = process.env.REACT_APP_FIRST_READ;
    const res = await axios.get(`${host}/autometrica/brands`, config);
    if (res && res.data && typeof(res.data) === 'string') { 
      res.data = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
    }
    return (res && res.data && res.data.brands) || []
  }

  async onChangeAutometricaBrand(_index_offset, newValue) {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let current_element = this.context.viewData[_index_offset];
    var busca = this.state.inputs[_index_offset];

    // ############################### GET MODELS ############################## //
    const token = process.env.REACT_APP_TOKEN;
    let query = {
      brand: newValue,
      year: busca.year,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      },
      params: query
    };
    const host = process.env.REACT_APP_FIRST_READ;
    const res = await axios.get(`${host}/autometrica/models`, config);
    if (res && res.data && typeof(res.data) === 'string') { 
      res.data = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
    }
    let models = (res && res.data && res.data.models) || [];

    let autometrica = busca.autometrica || {};
    delete autometrica.sell;
    delete autometrica.versions;
    delete autometrica.version;
    delete autometrica.models;
    delete autometrica.model;
    autometrica['brand'] = newValue;
    autometrica['models'] = models;
    busca.updated_by = user['email'];
    current_element.updated_by = user['email'];
    busca.autometrica = autometrica;
    current_element.autometrica = autometrica;

    this.saveInput(_index_offset, busca);
  }

  async onChangeAutometricaModel(_index_offset, newValue) {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let current_element = this.context.viewData[_index_offset];
    var busca = this.state.inputs[_index_offset];

    // ############################### GET VERSIONS ############################## //
    const token = process.env.REACT_APP_TOKEN;
    let query = {
      brand: busca.autometrica.brand,
      model: newValue,
      year: busca.year,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      },
      params: query
    };
    const host = process.env.REACT_APP_FIRST_READ;
    const res = await axios.get(`${host}/autometrica/versions`, config);
    if (res && res.data && typeof(res.data) === 'string') { 
      res.data = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
    }
    let versions = (res && res.data && res.data.versions) || [];

    let autometrica = busca.autometrica || {};
    delete autometrica.sell;
    delete autometrica.version;
    autometrica['model'] = newValue;
    autometrica['versions'] = versions;
    busca.updated_by = user['email'];
    current_element.updated_by = user['email'];
    busca.autometrica = autometrica;
    current_element.autometrica = autometrica;

    this.saveInput(_index_offset, busca);
  }

  async onChangeAutometricaVersion(_index_offset, newValue) {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let current_element = this.context.viewData[_index_offset];
    var busca = this.state.inputs[_index_offset];

    // ############################### GET DETAILS ############################## //
    const token = process.env.REACT_APP_TOKEN;
    let query = {
      brand: busca.autometrica.brand,
      model: busca.autometrica.model,
      year: busca.year,
      version: newValue,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      },
      params: query
    };
    const host = process.env.REACT_APP_FIRST_READ;
    const res = await axios.get(`${host}/autometrica/details`, config);
    if (res && res.data && typeof(res.data) === 'string') { 
      res.data = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
    }
    let details = (res && res.data) || {};

    let autometrica = busca.autometrica || {};
    delete autometrica.sell;
    if (details.model) {
      delete details.model;
    }
    if (!details.buy) {
      details.buy = "-";
    }
    autometrica = {
      ...autometrica,
      ...details
    }
    autometrica['version'] = newValue;
    busca.updated_by = user['email'];
    current_element.updated_by = user['email'];
    busca.autometrica = autometrica;
    current_element.autometrica = autometrica;

    this.saveInput(_index_offset, busca);
  }
  
  saveInput(_index_offset, busca) { 
    const updatedArray = [...this.state.inputs];
    updatedArray[_index_offset] = busca;
    this.setState({
      inputs: updatedArray,
    });
    this.context.setViewData(this.context.viewData);
    this.setDataToExport();
    this.forceUpdate();
  }

  async onChange(index, index2, newValue) {
    let _index_offset = index + this.state.offset;
    let current_element = this.context.viewData[_index_offset];
    var busca = current_element.versions[index2];

    // ############################ BUSCAR EN EL B2C ########################### //
    if (!busca.venta) {
      const token = process.env.REACT_APP_TOKEN;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        },
      };
      const host = process.env.REACT_APP_FIRST_READ;
      let query = {
        kilometers: current_element.kilometraje,
        year: busca.year,
        model: busca.model,
        brand: busca.brand,
        version: busca.version,
        tipo_impuesto: 3,
        precio_reaconidicinamiento_mxn:
        current_element.precio_reacondicionamiento_mxn,
        color: current_element.color,
        region: current_element.region
      };
      let res;
      try {
        res = await axios.post(`${host}/v1/predictor/b2c`, JSON.stringify(query), config);
        const fields = res.data[0];
        busca.color = fields.data.color;
        busca.region = fields.data.region;
        busca.compra_oferta3 = fields.compra_oferta3;
        busca.compra_oferta2 = fields.compra_oferta2;
        busca.compra_oferta1 = fields.compra_oferta1;
        busca.venta = fields.venta;
        busca.venta_atributos = fields.venta_atributos;
        busca.seminuevos_price = fields.seminuevos_price;
        busca.dias_demanda = fields.dias_demanda;
        busca.cantidad_demanda = fields.cantidad_demanda;
        busca.autometrica_details = fields.data.autometrica_details;
        busca.warnings = fields.data.warnings;
        if (fields.error) {
            busca.error = fields.error;
        } else {
          if (busca.error) { 
            delete busca.error;
          }
        }
      } catch (error) {
        // ToastsStore.error(
        //   `${error}`, 300000, "show"
        // );
        const error_message = error.response.data.message;
        busca.error = error_message;
      }
    }
    
    // {
    //     "price": 253347.97,
    //     "compra_oferta3": 228653.1254926109,
    //     "compra_oferta2": 222621.0309688014,
    //     "compra_oferta1": 216588.9364449918,
    //     "dias_demanda": 0.0,
    //     "cantidad_demanda": 0.0,
    //     "seminuevos_price": 216700.0,
    //     "data": {
    //         "rank": "40001-50000",
    //         "year": "2018",
    //         "model": "golf (a7)",
    //         "brand": "volkswagen",
    //         "version": "5 pts. hb comfortline, 1.4t, tm6, a/ac., qcp, f.niebla, ra-17",
    //         "color": "Marrón",
    //         "region": "Puebla"
    //     }
    // }

    fillSaleTooltip(busca);

    if (!busca.error) {
      delete current_element.error;
    }

    busca.selected = {
      ...busca.selected,
      version: index2
    }
    const updatedArray = [...this.state.inputs];
    updatedArray[_index_offset] = busca;
    this.setState({
      inputs: updatedArray,
    });
    current_element.selected = { ...current_element.selected, ...busca.selected };
    current_element.version = busca.version;
    delete current_element.error_code;
    if (!busca.error) {
      delete current_element.error;
    } else { 
      current_element.error = busca.error;
    }
    this.saveLine(
      _index_offset,
      current_element,
      true,
    )
    this.context.setViewData(this.context.viewData);
    this.setDataToExport();
  }

  async onChangeResolution(index, newValue) {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let _index_offset = index + this.state.offset;
    let current_element = this.context.viewData[_index_offset];
    var busca = this.state.inputs[_index_offset];

    busca.resolution = newValue;
    busca.updated_by = user['email'];
    const updatedArray = [...this.state.inputs];
    updatedArray[_index_offset] = busca;
    this.setState({
      inputs: updatedArray,
    });
    current_element.resolution = newValue
    current_element.updated_by = user['email'];
    this.context.setViewData(this.context.viewData);
    this.setDataToExport();
    this.saveLine(
      _index_offset,
      current_element,
    )
  }

  async saveLine(_index_offset, current_element, replace=false) { 
    let user = JSON.parse(window.localStorage.getItem("user"));
    let lineIndex = _index_offset;
    let line = this.context.data[lineIndex];
    if (replace) {
      line = current_element;
    }
    else
    { 
      line = {
        ...line,
        ...current_element
      }
    }
    const uuidActive = window.localStorage.getItem("uuidActive");
    axios
      .post(process.env.REACT_APP_UPDATE_DOCUMENT_LINE, {
        uuidActive,
        line,
        lineIndex,
        user
      })
      .then((res) => {
        this.forceUpdate();
      }).catch((err) => {
        alert(`Error al guardar los datos: ${err}`);
      });
  }

  async onChangeModel(index, index2, newValue) {
    let _index_offset = index + this.state.offset;
    let current_element = this.context.viewData[_index_offset];
    var busca = slice[index];
    if (busca.selected.model === index2) { 
      return;
    }
    var model = busca.extra_data.models[index2];
    busca.model = model;

    // ############################## GET VERSIONS ############################# //
    const token = process.env.REACT_APP_TOKEN;
    let query = {
      year: busca.year,
      model: busca.model,
      brand: busca.brand
    };
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        },
        params: query
      };
    const host = process.env.REACT_APP_FIRST_READ;
    const res = await axios.get(`${host}/versions`, config);
    let versions = res.data && res.data.versions;
    if (versions && versions.length) { 
      busca.versions = versions.map(
        (pd, _index) => {
          return {
            brand: res.data.brand,
            year: res.data.year,
            model: res.data.model,
            version: pd['version'],
            version_trim: pd['version_trim'],
          }
        }
      );
      busca.model = res.data.model;
      busca.version = '';
      // busca.version = busca.versions[0].version;
      // this.onChange(index, 0);
      // delete busca.error;
      busca.error = 'Falta especificar la versión';
      // delete busca.extra_data;
      
      busca.selected = {
        ...busca.selected,
        model: index2,
        version: undefined
      }
      const updatedArray = [...this.state.inputs];
      updatedArray[_index_offset] = busca;
      this.setState({
        inputs: updatedArray,
      });
      current_element.selected = { ...current_element.selected, ...busca.selected };
      current_element.model = busca.model;
      current_element.versions = busca.versions;
      delete current_element.error_code;
      if (!busca.error) {
        delete current_element.error;
      } else { 
        current_element.error = busca.error;
      }
      this.context.setViewData(this.context.viewData);
      this.setDataToExport();
      this.forceUpdate();
    }
  }

  async onChangeBrand(index, index2, newValue) {
    let _index_offset = index + this.state.offset;
    let current_element = this.context.viewData[_index_offset];
    var busca = slice[index];
    var brand = busca.extra_data.brands[index2];
    busca.brand = brand;

    // ############################## GET VERSIONS ############################# //
    const token = process.env.REACT_APP_TOKEN;
    let query = {
      year: busca.year,
      brand: busca.brand
    };
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        },
        params: query
      };
    const host = process.env.REACT_APP_FIRST_READ;
    const res = await axios.get(`${host}/models`, config);
    let models = (res.data && res.data.models).map((m) => m['model']);
    if (models && models.length) {
      busca.extra_data.models = models;
      busca.model = models[0];
      busca.versions = [];
      busca.version = '';
      busca.model = res.data.model;
      busca.error = 'Falta especificar el modelo';
      // delete busca.error;
      // delete busca.extra_data;
      busca.selected = {
        ...busca.selected,
        brand: index2,
        model: undefined,
        version: undefined
      }
      const updatedArray = [...this.state.inputs];
      updatedArray[_index_offset] = busca;
      this.setState({
        inputs: updatedArray,
      });
      current_element.selected = { ...current_element.selected, ...busca.selected };
      current_element.brand = busca.brand;
      current_element.extra_data.models = busca.extra_data.models;
      delete current_element.error_code;
      if (!busca.error) {
        delete current_element.error;
      } else { 
        current_element.error = busca.error;
      }
      this.context.setViewData(this.context.viewData);
      this.setDataToExport();
      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.receivedData();
  }

  render() {
    if (this.state.inputs.length === 0) {
      return <Spinner animation="grow" variant="dark" />;
    } else {
      return (
        <div className="row mt-50">
          <div className="col-12">
          <ToastsContainer
              store={ToastsStore}
              position={"top_center"}
              lightBackground
            />
            <table className="w-100">
              <thead>
                <tr>
                  <th>
                    Kilometraje
                  </th>
                  <th>
                    Año
                  </th>
                  <th>
                    Marca
                  </th>
                  <th>
                    Modelo
                  </th>
                  <th>
                    Versión
                  </th>
                  <th>
                    Color
                  </th>
                  <th>
                    Region
                  </th>
                  <th style={{ borderRight: '1px solid rgba(255, 255, 255, 0.5)'}}>
                    Costo reacondicionamiento (MXN$)
                  </th>
                  <th>
                    Días Prom. en Venderse
                  </th>
                  <th>
                    Cantidad de Autos Vendidos
                  </th>
                  <th>
                    Precio Compra Oferta 3 (MXN$)
                  </th>
                  <th>
                    Precio Compra Oferta 2 (MXN$)
                  </th>
                  <th>
                    Precio Compra Oferta 1 (MXN$)
                  </th>
                  <th>
                    Precio Venta con Color y Region (MXN$)
                  </th>
                  <th>
                    Precio Venta (MXN$)
                  </th>
                  <th>
                    Precio Autométrica (MXN$)
                  </th>
                  <th>
                    Resolución
                  </th>
                  <th>
                  </th>
                </tr>
              </thead>
              <Tbody>
                {this.state.slice.map((pd, _index) => {
                    let _index_offset = _index + this.state.offset;
                    let alto_kilometraje;
                    if (pd.kilometraje > 180000) {
                      alto_kilometraje = <>
                        <span>&nbsp;&nbsp;</span>
                        <Tooltip content="Vehículo con más de 180000 kilómetros">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </Tooltip>
                      </>
                    } else { 
                      alto_kilometraje = <></>
                    }
                  return <Tr key={_index} className="table-row mb-0 mt-0">
                    <Td>{pd.kilometraje}{alto_kilometraje}</Td>
                    <Td>{this.state.inputs[_index_offset].year}</Td>
                    <Td>
                    {
                      (pd.extra_data && pd.extra_data.brands) ? (
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          size="sm"
                          color="#f1f1f1"
                          caret
                          >
                            {
                              // pd.extra_data.brands.includes(pd.brand)?pd.brand:""
                              this.state.inputs[_index_offset].brand
                            }
                        </DropdownToggle>
                            <DropdownMenu style={{
                              maxHeight: "300px",
                              overflowY: "scroll"
                            }}>
                            {
                              pd.extra_data.brands.map((input, index) => (
                            <DropdownItem
                              key={index}
                              id={index}
                              name={input}
                              onClick={(e) => {
                                this.onChangeBrand(_index, index, e.target.name);
                              }}
                            >
                              {input}{" "}
                            </DropdownItem>
                          ))
                            }
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                      ): (
                          <>{
                            this.state.inputs[_index_offset].brand
                          }</>
                      )
                    }
                    </Td>
                    <Td>{
                      (pd.extra_data && pd.extra_data.models) ? (
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          size="sm"
                          color="#f1f1f1"
                          caret
                          >
                            {
                              this.state.inputs[_index_offset].model
                            }
                        </DropdownToggle>
                        <DropdownMenu style={{
                              maxHeight: "300px",
                              overflowY: "scroll"
                            }}>
                            {
                              pd.extra_data.models.map((input, index) => (
                            <DropdownItem
                              key={index}
                              id={index}
                              name={input}
                              onClick={(e) => {
                                this.onChangeModel(_index, index, e.target.name);
                              }}
                            >
                              {input}{" "}
                            </DropdownItem>
                          ))
                            }
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                      ): (
                          <>{
                            this.state.inputs[_index_offset].model
                          }</>
                      )
                    }</Td>
                    <Td>
                      {" "}
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          size="sm"
                          color="#f1f1f1"
                          caret
                        >
                          {
                            getDowndownVersion(this.state.inputs[_index_offset])
                          }
                        </DropdownToggle>
                        <DropdownMenu style={{
                              maxHeight: "300px",
                              overflowY: "scroll"
                            }}>
                          {((pd.versions && pd.versions.length && pd.versions) || []).map((input, index) => (
                            <DropdownItem
                              key={index}
                              id={index}
                              name={input.version}
                              onClick={(e) => {
                                this.onChange(_index, index, e.target.name);
                              }}
                            >
                              {getDowndownVersion(input)}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Td>

                    <Td>{pd.color}</Td>
                    <Td>{pd.region}</Td>
                    <Td>
                      {formatToCurrency(pd.precio_reacondicionamiento_mxn)}
                    </Td>
                    <Td>
                      {this.state.inputs[_index_offset].dias_demanda && this.state.inputs[_index_offset].dias_demanda !== -1?parseFloat(this.state.inputs[_index_offset].dias_demanda)
                .toFixed(2):'Desconocida'}
                    </Td>
                    <Td>
                      {this.state.inputs[_index_offset].cantidad_demanda && this.state.inputs[_index_offset].cantidad_demanda !==-1 ?parseFloat(this.state.inputs[_index_offset].cantidad_demanda)
                .toFixed(2):'Desconocida'}
                    </Td>
                    <Td>
                      <span className="description d-md-none">
                        Precio compra Oferta 3
                      </span>
                      {formatToCurrency(
                        this.state.inputs[_index_offset].compra_oferta3
                      )}
                    </Td>
                    <Td>
                      <span className="description d-md-none">
                        Precio Compra Oferta 2
                      </span>
                      {formatToCurrency(
                        this.state.inputs[_index_offset].compra_oferta2
                      )}
                    </Td>
                    <Td>
                      <span className="description d-md-none">
                        Precio Compra Oferta 1
                      </span>
                      {formatToCurrency(
                        this.state.inputs[_index_offset].compra_oferta1
                      )}
                    </Td>
                    <Td>
                      <span className="description d-md-none">
                        Precio venta considerando atributos
                      </span>
                      {
                        formatToCurrency(this.state.inputs[_index_offset].venta_atributos)
                      }
                    </Td>
                    <Td>
                      <span className="description d-md-none">
                        Precio venta
                      </span>
                      {this.state.inputs[_index_offset].sale_tooltip !== undefined ? (
                        <Tooltip content={this.state.inputs[_index_offset].sale_tooltip}>
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </Tooltip>
                      ) : (<></>)}
                      {formatToCurrency(this.state.inputs[_index_offset].venta)}
                    </Td>
                    <Td>
                      {
                        this.state.inputs[_index_offset].venta && this.state.inputs[_index_offset].autometrica_details && this.state.inputs[_index_offset].autometrica_details.sell
                          ?
                          <Tooltip
          content={JSON.stringify(this.state.inputs[_index_offset].autometrica_details, null, 4)}>
                          <div style={{ minWidth: '120px', verticalAlign: 'middle', display: 'table'}}>
                            <span style={{ verticalAlign: 'middle', display: 'table-cell'}}>{this.state.inputs[_index_offset].autometrica_details && this.state.inputs[_index_offset].autometrica_details.sell && formatToCurrency(this.state.inputs[_index_offset].autometrica_details.sell)}</span>
                            <button
                            className="btn -fabtn -red float-end"
                            onClick={(e) =>
                            this.onClickDeleteAutometrica(_index_offset)
                            }
                            >
                              <i
                              id={_index}
                              className="fas fa-trash-alt"
                                /> 
                            </button>
                          </div>
                          </Tooltip>
                          : (this.state.inputs[_index_offset].venta?
                          <button
                          className="btn -fabtn -secondary float-end"
                          onClick={(e) =>
                          this.onClickAutometrica(_index_offset)
                          }
                          >
                            <i
                            id={_index}
                            className="fas fa-pen-alt"
                              /> 
                          </button>:<></>
                          )
                      }
                    </Td>
                    <Td>
                      {
                        (this.state.inputs[_index_offset].venta && this.state.resolutions) ? <>
                          <ConditionalWrapper
        condition={this.state.inputs[_index_offset].resolution_data}
        wrapper={children => <Tooltip
          content={JSON.stringify(this.state.inputs[_index_offset].resolution_data, null, 4)}>{children}</Tooltip>}
      >
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          size="sm"
                          color="#f1f1f1"
                          caret
                          >
                            {
                              this.state.inputs[_index_offset].resolution
                            }
                        </DropdownToggle>
                        <DropdownMenu style={{
                              maxHeight: "300px",
                              overflowY: "scroll"
                            }}>
                            {
                              this.state.resolutions.map((input, index) => (
                            <DropdownItem
                              key={index}
                              id={index}
                              name={input}
                              onClick={(e) => {
                                this.onChangeResolution(_index, e.target.name);
                              }}
                            >
                              {input}{" "}
                            </DropdownItem>
                          ))
                            }
                        </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </ConditionalWrapper>
                        </>: (
                          <></>
                      )
                    }
                      {
                        (
                          this.state.inputs[_index_offset].resolution &&
                          this.state.inputs[_index_offset].resolution_data &&
                          this.state.inputs[_index_offset].resolution_data.price &&
                          (
                          this.state.inputs[_index_offset].resolution === "ACEPTADA" ||
                          this.state.inputs[_index_offset].resolution === "RECHAZADA COMPRADOR"
                          )
                        ) ?
                          <><br/>
                            &nbsp;&nbsp;{formatToCurrency(this.state.inputs[_index_offset].resolution_data.price)}</>
                          :
                          <></>
                      }
                    </Td>
                    <Td>
                      {
                        (
                          this.state.inputs[_index_offset].resolution
                          &&
                            (
                            this.state.inputs[_index_offset].resolution === "ACEPTADA" ||
                            this.state.inputs[_index_offset].resolution === "RECHAZADA COMPRADOR"
                          )
                        ) ?
                          (

                          <button
                          className="btn -fabtn -secondary float-end"
                          onClick={(e) =>
                          this.onClickResolution(_index_offset)
                          }
                          >
                            <i
                            id={_index}
                            className="fas fa-pen-alt"
                              /> 
                          </button>
                          )
                          : <></>
                      }
                    </Td>
                  </Tr>
                })}
              </Tbody>
            </table>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"......."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
          <Modal onKeyPress={this.resolutionOnKeyUp} isOpen={this.state.resolutionModal} toggle={this.setResolutionModal} className={this.props.className}>
          <ModalHeader className="title" toggle={this.setResolutionModal}>Resolución</ModalHeader>
            <ModalBody className="pop-text">
              {this.state.is_accepted ? 
                <>
                  En cuánto quedo la negociación?
                </>
               :
               <>  
                Cuál es el precio real?
                </>
              }
              
              <hr></hr>
              <Input type="text" innerRef={this.modalPrice} placeholder="Precio" defaultValue={this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].resolution_data && this.state.inputs[this.state.index_offset].resolution_data.price ?this.state.inputs[this.state.index_offset].resolution_data.price: 0}/>
              <small className="text-muted">
              <div className="float-end">
              {this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].updated_by}
              </div>
              <div className="float-start">
              {this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].updated}
              </div>
              </small>
          </ModalBody>
          <ModalFooter className="justify-content-around">
              <button className="btn -secondaryminor" onClick={this.setResolutionModal}>Cancelar</button>
              <button className="btn -secondary" onClick={this.setResolution}>Guardar</button>
          </ModalFooter>
        </Modal>
          <Modal onKeyPress={this.autometricaOnKeyUp} isOpen={this.state.autometricaModal} toggle={this.setAutometricaModal} className={this.props.className}>
          <ModalHeader className="title" toggle={this.setAutometricaModal}>Mapear versión autométrica</ModalHeader>
            <ModalBody className="pop-text">
              <table>
                <Tr>
                  <Td>
                    Marca ({ this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].brand})
                  </Td>
                  <Td>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      size="sm"
                      color="#f1f1f1"
                      caret
                      >
                        {
                          this.state.inputs[this.state.index_offset] && (
                            (
                              this.state.inputs[this.state.index_offset].autometrica && this.state.inputs[this.state.index_offset].autometrica.brand
                            ) || this.state.inputs[this.state.index_offset].brand || ""
                          )
                        }
                    </DropdownToggle>
                    <DropdownMenu style={{
                      maxHeight: "300px",
                      overflowY: "scroll"
                    }}>
                        {
                          (this.state && this.state.autometrica && this.state.autometrica.brands).map((input, index) => (
                        <DropdownItem
                          key={index}
                          id={index}
                          name={input}
                          onClick={(e) => {
                            this.onChangeAutometricaBrand(this.state.index_offset, e.target.name);
                          }}
                          >
                          {input}{" "}
                        </DropdownItem>
                      ))
                    }
                    </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Td>
                </Tr>
              {
                 (this.state && this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].autometrica && this.state.inputs[this.state.index_offset].autometrica.models) ?
                  <>
                  <Tr>
                  <Td>
                    Modelo ({ this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].model})
                  </Td>
                  <Td>
                  <UncontrolledButtonDropdown>
                  <DropdownToggle
                    size="sm"
                    color="#f1f1f1"
                    caret
                    >
                      {
                          this.state.inputs[this.state.index_offset].autometrica.model ||
                          this.state.inputs[this.state.index_offset].model ||
                          ""
                      }
                  </DropdownToggle>
                  <DropdownMenu style={{
                    maxHeight: "300px",
                    overflowY: "scroll"
                  }}>
                      {
                        (this.state.inputs[this.state.index_offset].autometrica && this.state.inputs[this.state.index_offset].autometrica.models).map((input, index) => (
                      <DropdownItem
                        key={index}
                        id={index}
                        name={input}
                        onClick={(e) => {
                          this.onChangeAutometricaModel(this.state.index_offset, e.target.name);
                        }}
                        >
                        {input}{" "}
                      </DropdownItem>
                    ))
                  }
                  </DropdownMenu>
                        </UncontrolledButtonDropdown>
                  </Td></Tr>
                  </>
                  :
                  <></>
              }
              {
                 (this.state && this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].autometrica && this.state.inputs[this.state.index_offset].autometrica.versions) ?
                  <>
                  <Tr>
                  <Td>
                    Versión ({ this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].version})
                  </Td>
                  <Td>
                  <UncontrolledButtonDropdown>
                  <DropdownToggle
                    size="sm"
                    color="#f1f1f1"
                    caret
                    >
                      {
                          this.state.inputs[this.state.index_offset].autometrica.version ||
                          this.state.inputs[this.state.index_offset].version ||
                          ""
                      }
                  </DropdownToggle>
                  <DropdownMenu style={{
                    maxHeight: "300px",
                    overflowY: "scroll"
                  }}>
                      {
                        (this.state.inputs[this.state.index_offset].autometrica && this.state.inputs[this.state.index_offset].autometrica.versions).map((input, index) => (
                      <DropdownItem
                        key={index}
                        id={index}
                        name={input}
                        onClick={(e) => {
                          this.onChangeAutometricaVersion(this.state.index_offset, e.target.name);
                        }}
                        >
                        {input}{" "}
                      </DropdownItem>
                    ))
                  }
                  </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    </Td></Tr>
                  </>
                  :
                  <></>
              }
              </table>
              {
                (this.state && this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].autometrica && this.state.inputs[this.state.index_offset].autometrica.sell) ?
                  <>
                    <table>
                      <Tr>
                        <Td>
                          Venta
                        </Td>
                        <Td>
                          {formatToCurrency(this.state.inputs[this.state.index_offset].autometrica.sell)}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          Compra
                        </Td>
                        <Td>
                          {formatToCurrency(this.state.inputs[this.state.index_offset].autometrica.buy)}
                        </Td>
                      </Tr>
                    </table>
                  </>
                  :
                  <></>
              }
              <hr/>
              <small className="text-muted">
              <div className="float-end">
              {this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].updated_by}
              </div>
              <div className="float-start">
              {this.state.inputs[this.state.index_offset] && this.state.inputs[this.state.index_offset].updated}
              </div>
              </small>
          </ModalBody>
          <ModalFooter className="justify-content-around">
              <button className="btn -secondaryminor" onClick={this.setAutometricaModal}>Cancelar</button>
              {
                (this.state.inputs[this.state.index_offset] &&
                this.state.inputs[this.state.index_offset].autometrica &&
                this.state.inputs[this.state.index_offset].autometrica.sell) ? <button className="btn -secondary" onClick={this.setAutometrica}>Guardar</button> : <></>
              }
          </ModalFooter>
        </Modal>
        </div>
      );
    }
  }

  async onClickResolution(index_offset) {
    const is_accepted = this.context.viewData[index_offset] && this.context.viewData[index_offset].resolution === "ACEPTADA";
    this.setState({
      index_offset,
      is_accepted
    });
    this.setResolutionModal();
  }

  async onClickAutometrica(_index_offset) {
    var busca = this.state.inputs[_index_offset];
    let current_element = this.context.viewData[_index_offset];
    const token = process.env.REACT_APP_TOKEN;
    let query = {
      brand: busca.brand,
      model: busca.model,
      year: busca.year,
      version: busca.version,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      },
      params: query
    };
    const host = process.env.REACT_APP_FIRST_READ;
    try {
      const res = await axios.get(`${host}/autometrica/details_by_jato`, config);
      if (res && res.data && typeof(res.data) === 'string') { 
        res.data = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
      }
      busca.autometrica = res.data;
      current_element.autometrica = res.data;
      this.saveInput(_index_offset, busca);
    } catch (e) { 

    }

    this.setState({
      index_offset: _index_offset,
    });
    this.setAutometricaModal();
  }
  
  async onClickDeleteAutometrica(_index_offset) {
    this.setState({
      index_offset: _index_offset,
    });
    let current_element = this.context.viewData[_index_offset];
    var busca = this.state.inputs[_index_offset];

    let user = JSON.parse(window.localStorage.getItem("user"));
    current_element.updated_by = user['email'];
    this.context.setViewData(this.context.viewData);
    this.setDataToExport();
    
    let query = {
      jato_id: busca.autometrica_details.jato_id
    };
    const token = process.env.REACT_APP_TOKEN;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
        "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      },
      params: query
    };
    try {
      const host = process.env.REACT_APP_FIRST_READ;
      const res = await axios.delete(`${host}/autometrica/mapping`, config);
      if (res && res.data && typeof(res.data) === 'string') { 
        res.data = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
      }
      delete busca.autometrica_details;
      delete busca.autometrica;
      delete current_element.autometrica_details;
      delete current_element.autometrica;
      this.saveInput(_index_offset, busca);
      this.saveLine(
        _index_offset,
        current_element,
        true
      )
      this.setAutometricaModal();
    } catch (error) {
      ToastsStore.error((error && error.response && error.response.data && error.response.data.error) || error);
    }
  }

  async setAutometrica() {
    let _index_offset = this.state.index_offset;
    let current_element = this.context.viewData[_index_offset];
    var busca = this.state.inputs[_index_offset];

    let user = JSON.parse(window.localStorage.getItem("user"));
    current_element.updated_by = user['email'];
    this.context.setViewData(this.context.viewData);
    this.setDataToExport();
    
    let query = {
      brand: current_element.autometrica.brand,
      model: current_element.autometrica.model,
      year: current_element.year,
      version: current_element.autometrica.version,
      
      j_brand: busca.brand,
      j_model: busca.model,
      j_year: busca.year,
      j_version: busca.version,
    };
    const token = process.env.REACT_APP_TOKEN;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
        "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      },
      params: query
    };
    try {
      const host = process.env.REACT_APP_FIRST_READ;
      const res = await axios.post(`${host}/autometrica/mapping`, {}, config);
      if (res && res.data && typeof(res.data) === 'string') { 
        res.data = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
      }
      busca.autometrica_details = res.data;
      current_element.autometrica_details = res.data;
      this.saveInput(_index_offset, busca);
      this.saveLine(
        _index_offset,
        current_element,
      )
      this.setAutometricaModal();
    } catch (error) {
      ToastsStore.error((error && error.response && error.response.data && error.response.data.error) || error);
    }
  }

  async setResolution() {
    let _index_offset = this.state.index_offset;
    let current_element = this.context.viewData[_index_offset];
    let lineIndex = _index_offset;
    
    let user = JSON.parse(window.localStorage.getItem("user"));
    current_element.resolution_data = current_element.resolution_data || {};
    current_element.resolution_data.price = this.modalPrice.current.value;
    current_element.updated_by = user['email'];
    this.context.setViewData(this.context.viewData);
    this.setDataToExport();
    
    let line = this.context.data[lineIndex];
    line = {
      ...line,
      ...current_element
    }
    try {
      this.setResolutionModal();
      const uuidActive = window.localStorage.getItem("uuidActive");
      const res = await axios.post(
        process.env.REACT_APP_UPDATE_DOCUMENT_LINE,
        {
          uuidActive,
          line,
          lineIndex,
          user
        }
      )
      const updatedArray = [...this.state.inputs];
      updatedArray[_index_offset].resolution_data = current_element.resolution_data;
      updatedArray[_index_offset].updated = current_element.updated;
      updatedArray[_index_offset].updated_by = current_element.updated_by;
      this.setState({
        inputs: updatedArray,
      });
      this.forceUpdate();
    } catch (error) {
      ToastsStore.error((error && error.response && error.response.data && error.response.data.error) || error);
    }
  }

  setResolutionModal = () => {
    this.setState({
      resolutionModal: !this.state.resolutionModal,
    });
  }

  setAutometricaModal = () => {
    this.setState({
      autometricaModal: !this.state.autometricaModal,
    });
  }

  resolutionOnKeyUp(event) {
    if (event.key === "Enter") {
      
      this.setResolution();
    }
  }

  autometricaOnKeyUp(event) {
    if (event.key === "Enter") {
      
      this.setAutometrica();
    }
  }
}

export default PaginationComponent;

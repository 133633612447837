import React, { Component } from "react";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";
import Map from "../Components/Map/Map";
import { NavLink } from "../../node_modules/reactstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
import axios from "axios";

class MapUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      markers: [],
      user: "",
    };
  }

  setUser = (event) => {
    //console.log(event.target);
    this.setState({
      user: event.target.value,
    });

    this.getPines(event.target.value);
  };

  getPines = (event) => {
    var email = event;
    try {
      axios
        .post(process.env.REACT_APP_URL_GET_LOCATION, { email })
        .then((res) => {
          this.setState({ markers: res.data }, () => {
            window.localStorage.setItem("markers", JSON.stringify(res.data));
          });
        });
    } catch (error) {}
    //this.props.thisHistory.push("/map-user");
  };

  // getMap = () => {
  //   var email = window.localStorage.getItem("mapUser");

  //   try {
  //     axios
  //       .post(process.env.REACT_APP_URL_GET_LOCATION, { email })
  //       .then((res) => {
  //         this.setState({ markers: res.data }, () => {
  //           window.localStorage.setItem("markers", JSON.stringify(res.data));
  //           this.setState({ visible: false });
  //         });
  //       });
  //   } catch (error) {}
  //   this.props.thisHistory.push("/map-user");
  // };

  componentDidMount() {
    // this.getMap();
    this.setState({ visible: false });
  }
  render() {
    return (
      <div className="container-fluid px-0">
        <div className="container-fluid d-flex flex-column flex-md-row px-0 mt-navbar">
          <div className="container">
            {this.state.visible === true ? (
              <LoadingScreen type={"loading-map"} />
            ) : (
              <div className="container">
                <div className="row">
                  <NavLink
                    className="sidenavbutton"
                    tag={RouterNavLink}
                    to="/user-admin"
                    exact
                  >
                    <i className="fas fa-arrow-left fs-25"></i>
                  </NavLink>
                  <div className="col-12">
                    <div className="col-12 col-lg-6 mt-20">
                      <div className="text-field">
                        <select
                          //defaultValue={this.state.selectValue}
                          onChange={this.setUser}
                          type="email"
                        >
                          <option
                            key={0}
                            value="none"
                            rol="none"
                            selected
                            disabled
                          >
                            Selecciona el usuario
                          </option>
                          {JSON.parse(
                            window.localStorage.getItem("getUsers")
                          ).map((input, index) =>
                            input.user_metadata.active === "true" &&
                            input.user_metadata.environment ===
                              process.env.REACT_APP_URL_ENVIRONMENT ? (
                              <option
                                key={index}
                                id={input.email}
                                value={input.email}
                              >
                                {input.name} - {input.user_metadata.rol}
                              </option>
                            ) : (
                              <></>
                            )
                          )}
                        </select>
                        <span className="bar"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <Map pines={this.state.markers} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MapUser;

import React, { Component } from "react";
import { ToastsContainer, ToastsStore } from "react-toasts";
import axios from "axios";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { pass1: "", pass2: "", pass3: "" };
  }

  setPassword1 = (event) => {
    this.setState({ pass1: event.target.value });
  };

  setPassword2 = (event) => {
    this.setState({ pass2: event.target.value });
  };

  setPassword3 = (event) => {
    this.setState({ pass3: event.target.value });
  };
  updatePassword = () => {
    if (
      this.state.pass1 !== "" &&
      this.state.pass2 !== "" &&
      this.state.pass3 !== ""
    ) {
      if (this.state.pass2 === this.state.pass3) {
        let option = "password";
        let user = JSON.parse(window.localStorage.getItem("user"));
        let password = this.state.pass3;
        axios
          .post(process.env.REACT_APP_URL_UPDATE_PASSWORD, {
            option,
            user,
            password,
          })
          .then((res) => {
            if (res.data.update) {
              ToastsStore.success("La contraseña se actualizó correctamente");
            } else {
              ToastsStore.error(res.data.update);
            }
          });
      } else {
        ToastsStore.error("La nueva contraseña no coincide");
      }
    } else {
      ToastsStore.error("Verifica los campos vacíos");
    }
  };

  render() {
    return (
      <div className="container-fluid px-0">
        <div className="container mt-navbar">
          <div className="row mt-30">
            <div className="col-12 col-md-8">
              <p className="title mt-50">Actualizar Contraseña</p>
              <ToastsContainer
                store={ToastsStore}
                position={"top_center"}
                lightBackground
              />
            </div>
          </div>

          <div className="row my-30">
            <div className="col-12 col-lg-3">
              <div className="text-field">
                <input type="password" onChange={this.setPassword1} required />
                <span className="bar"></span>
                <label>Contraseña actual</label>
              </div>
            </div>
          </div>

          <div className="row my-30">
            <div className="col-12 col-lg-3">
              <div className="text-field">
                <input type="password" onChange={this.setPassword2} required />
                <span className="bar"></span>
                <label>Nueva Contraseña</label>
              </div>
            </div>
          </div>

          <div className="row my-30">
            <div className="col-12 col-lg-3">
              <div className="text-field">
                <input type="password" onChange={this.setPassword3} required />
                <span className="bar"></span>
                <label>Confirmar Contraseña</label>
              </div>
            </div>
          </div>

          <div className="row mt-30">
            <div className="col-12 col-lg-8"></div>
          </div>
          <div className="row mt-30 ">
            <div className="col-12 col-lg-3">
              <button
                className="btn -primary w-100"
                onClick={() => this.updatePassword()}
              >
                ACTUALIZAR
              </button>
            </div>
          </div>

          {/* <div className="row mt-30 ">
            <div className="col-12">
              {this.state.newUpload === false ? (
                <button
                  className="btn -primary ml-auto"
                  onClick={() => this.props.thisHistory.push("/net-amount")}
                >
                  CAPTURAR INGRESOS NETOS
                </button>
              ) : (
                <button
                  className="btn -primary ml-auto"
                  onClick={() => this.props.thisHistory.push("/net-amount")}
                >
                  CALCULO DIRECTO
                </button>
              )}
            </div>
          </div> */}

          {/* <div className="row">
          <div className="col-12">
            <Download button2="DESCARGAR" button1="CANCELAR" buttonLabel="Ejemplo de modal"></Download>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Delete button2="ELIMINAR" button1="CANCELAR" buttonLabel="Ejemplo de modal"></Delete>
          </div>
        </div> */}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import HomeEmpty from "../../Images/svg/Home__Empty.svg";
import Loading from "../../Images/gear.gif";
import Admin from "../../Images/svg/Admin.svg";

class LoadingScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="loading-screen">
        {this.props.type === "home" && (
          <div className="inner">
            <img className="mb-30" src={HomeEmpty} alt="" />
            <p className="minititle mb-20">Aún no tienes documentos</p>

            <button
              className="btn -primary"
              onClick={() => this.props.history.push("/upload")}
            >
              Agregar documento
            </button>
          </div>
        )}

        {this.props.type === "loading" && (
          <div className="inner">
            <img className="loading mb-30" src={Loading} alt="" />
            <p className="minititle mb-20">Tu documento se está procesando</p>
          </div>
        )}

        {this.props.type === "loading-main" && (
          <div className="inner">
            <img className="loading mb-30" src={Loading} alt="" />
          </div>
        )}

        {this.props.type === "loading-users" && (
          <div className="inner">
            <img className="loading mb-30" src={Loading} alt="" />
            <p className="minititle mb-20">Actualizando los usuarios</p>
          </div>
        )}

        {this.props.type === "loading-map" && (
          <div className="inner">
            <img className="loading mb-30" src={Loading} alt="" />
            <p className="minititle mb-20">Actualizando mapa</p>
          </div>
        )}

        {this.props.type === "loading-agenda" && (
          <div className="inner">
            <img className="loading mb-30" src={Loading} alt="" />
            <p className="minititle mb-20">Actualizando agenda</p>
          </div>
        )}

        {this.props.type === "activeFalse" && (
          <div className="inner">
            <img className="mb-30" src={Admin} alt="" />
            <p className="minititle">
              Tu usuario no esta activo, contacta a tu administrador
            </p>
          </div>
        )}

        {this.props.type === "locationFalse" && (
          <div className="inner">
            <img className="mb-30" src={Admin} alt="" />
            <p className="minititle">
              Para continuar, recuerda activar la ubicación de tu explorador
            </p>
          </div>
        )}

        {this.props.type === "admin" && (
          <div className="inner">
            <img className="mb-30" src={Admin} alt="" />
            <p className="minititle">Aún no tienes usuarios registrados</p>
            <p className="mb-20">
              Los usuarios qué registres tendrán acceso a la plataforma
            </p>
            <a href="/upload">
              <button className="btn -primary">Registrar usuarios</button>
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default LoadingScreen;
